// react libs
import React, { useState, useRef, useEffect, useContext } from "react";

// 3rd party react libs

// 3rd party libs

// melodisto components
import { MusicStandContext } from "../App";
import { UserContext } from "../App";

// melodisto helper modules
import { projectAuth } from '../firebase/config';

// melodisto styles
import styles from './DocumentHighlighter.module.css';

function DocumentHighlighter(props) {
    const BLANK_STATE = { x: 1, y: 1, width: 1, height: 1 };

    const [userContext] = useContext(UserContext);
    const [localHighlightNormalized, setLocalHighlightNormalized] = useState(BLANK_STATE);
    const [localHighlight, setLocalHighlight] = useState(BLANK_STATE);
    const [remoteHighlight, setRemoteHighlight] = useState(BLANK_STATE);
    const [drawing, setDrawing] = useState(BLANK_STATE);
  
    const isMouseDown = useRef(false);
    const wasMouseMoved = useRef(false);
    const wasBlankState = useRef(false);
    const canvasRef = useRef();
    const [musicStand, setMusicStand] = useContext(MusicStandContext);
    const isUserHost = useRef(false);

    useEffect(() => {
      isUserHost.current = props.roomId === userContext.email;
    }, [props.roomId]);

    useEffect(() => {
      if (isUserHost.current) {
        if (!musicStand.guestHighlights) return;

        setRemoteHighlight({
          x: musicStand.guestHighlights.x,
          y: musicStand.guestHighlights.y,
          width: musicStand.guestHighlights.width,
          height: musicStand.guestHighlights.height
        });
      } else {
        if (!musicStand.hostHighlights) return;

        setRemoteHighlight({
          x: musicStand.hostHighlights.x,
          y: musicStand.hostHighlights.y,
          width: musicStand.hostHighlights.width,
          height: musicStand.hostHighlights.height
        });
      }
    }, [musicStand]);

    useEffect(() => {
      // adjust the dimensions of the localHighlightNormalized by the zoomFactor
      const scaledHighlight = scale(localHighlightNormalized, true);
      setLocalHighlight(scaledHighlight);
    }, [props.zoomFactor]);

    // after the user drew a rectangle
    useEffect(() => {
      // disregard updates to the `BLANK_STATE`
      if (localHighlight.width === BLANK_STATE.width || localHighlight.height === BLANK_STATE.height) return;

      if (isUserHost.current) {
        setMusicStand({...musicStand, hostHighlights: localHighlightNormalized, modifiedBy: userContext.email});
      } else {
        setMusicStand({...musicStand, guestHighlights: localHighlightNormalized, modifiedBy: userContext.email});
      }
    }, [localHighlightNormalized]);

    const handleMouseDown = (event) => {
      isMouseDown.current = true;
      wasMouseMoved.current = false;
      setLocalHighlight(BLANK_STATE);
      setLocalHighlightNormalized(BLANK_STATE);
      const canvasOffset = canvasRef.current.getBoundingClientRect();
      const mouseX = event.clientX - canvasOffset.left;
      const mouseY = event.clientY - canvasOffset.top;
      setDrawing({ x: mouseX, y: mouseY, width: 1, height: 1 });
    };
  
    const handleMouseMove = (event) => {
      if (!isMouseDown.current) return;
      wasMouseMoved.current = true;

      const lastMouseX = drawing.x;
      const lastMouseY = drawing.y;
      const canvasOffset = canvasRef.current.getBoundingClientRect();
      const mouseX = event.clientX - canvasOffset.left;
      const mouseY = event.clientY - canvasOffset.top;
      const width = mouseX - lastMouseX;
      const height = mouseY - lastMouseY;
      setDrawing({
        x: lastMouseX,
        y: lastMouseY,
        width: width,
        height: height
      });
    };
  
    const handleMouseUp = (event) => {
      isMouseDown.current = false;
      const lastMouseX = drawing.x;
      const lastMouseY = drawing.y;
      const canvasOffset = canvasRef.current.getBoundingClientRect();
      const mouseX = event.clientX - canvasOffset.left;
      const mouseY = event.clientY - canvasOffset.top;
      if (!wasBlankState.current || mouseX - lastMouseX !== 0) {
        const dim = {
          x: lastMouseX,
          y: lastMouseY,
          width: mouseX - lastMouseX,
          height: mouseY - lastMouseY
        };
        setLocalHighlight(dim);

        // calculate the real dimensions by compensating them by the zoomFactor
        setLocalHighlightNormalized(scale(dim, false));
      }
      wasMouseMoved.current = false;
      wasBlankState.current = (mouseX - lastMouseX === 0) ? true : false;
      setDrawing(BLANK_STATE);
    };

    const scale = (highlight, isRemote) => {
      const zoom = isRemote ? props.zoomFactor : 1/props.zoomFactor;
      if (!highlight) return null;
      return {
        x: Math.round(highlight.x * zoom),
        y: Math.round(highlight.y * zoom),
        width: Math.round(highlight.width * zoom),
        height: Math.round(highlight.height * zoom)
      }
    }
  
    return (
      <div
        className={styles.canvas}
        style={{
          width: props.pdfDocContainerDimensions.width + 'px',
          height: props.pdfDocContainerDimensions.height + 'px'
        }}
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >

        <div // the local rectangle while mouseDown
          className={styles.drawing}
          style={{
            left: drawing.x + 'px',
            top: drawing.y + 'px',
            width: drawing.width + 'px',
            height: drawing.height + 'px'
          }}
        />
        <div // the local rectangle on mouseUp
          className={isUserHost.current ? styles.hostRectangle : styles.guestRectangle}
          style={{
            left: localHighlight.x + 'px',
            top: localHighlight.y + 'px',
            width: localHighlight.width + 'px',
            height: localHighlight.height + 'px'
          }}
        />
        <div // the remote highlight rectangle (adjusted by the zoonFactor)
          className={isUserHost.current ? styles.guestRectangle : styles.hostRectangle}
          style={{
            left: `calc(${remoteHighlight.x}px * ${props.zoomFactor})`,
            top: `calc(${remoteHighlight.y}px * ${props.zoomFactor})`,
            width: `calc(${remoteHighlight.width}px * ${props.zoomFactor})`,
            height: `calc(${remoteHighlight.height}px * ${props.zoomFactor})`
          }}
        />

      </div>
    );
  
}

export default DocumentHighlighter;
