// react libs
import React, { useState, useEffect, useRef } from 'react';

// 3rd party react libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Draggable from 'react-draggable';

// 3rd party libs

// melodisto components

// melodisto helper modules

// melodisto styles
import styles from './AudioPlayer.module.css';

// global variables


function AudioPlayer(props) {

  const [isRemotePlay, setIsRemotePlay] = useState(false);
  const [volume, setVolume] = useState(100);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [paused, setPaused] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [newTime, setNewTime] = useState(0);
  const isPlayerTimeUpdate = useRef(false);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (props.currentPlayerTime === -1) {
      setPaused(true);
      return;
    }
    if (!isPlayerTimeUpdate.current) {
      setCurrentTime(props.currentPlayerTime);
    }
  }, [props.currentPlayerTime]);

  useEffect(() => {
    generatePlayerState();
  }, [paused]);

  useEffect(() => {
    setCurrentTime(newTime);
  }, [newTime]);

  const generatePlayerState = (source) => {
    const playerState_ = {volume: volume/100, currentTime, playbackRate, paused};
    props.setRemoteControlledPlayerState(playerState_);
  }

  const updateCurrentPlayerTime = () => {
    props.updateCurrentPlayerTime(newTime);
    isPlayerTimeUpdate.current = false;
  }

  const toggleRemotePlay = (e) => {
    console.log(volume, playbackRate, paused, currentTime, isRemotePlay);
  }

  const beautifyTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

  return (

    <Draggable defaultPosition={{x: 0, y: 0}} handle=".handle" bounds="parent">
      <div className={styles.draggablePlayer}>

        <div className={styles.draggableTitle + ' handle'}>
          <div className="noselect modal-title">MP3 Player</div>
          <button type="button" className={styles.closeButton} onClick={props.doHideAudioPlayerModal} onTouchEnd={props.doHideAudioPlayerModal}>×</button>
        </div>
        
        <div className={styles.playControls}>
          <div className="lever">
            <input className="slider" type="range" min=".5" max="2" step=".1"
              value={playbackRate}
              onChange={(e) => setPlaybackRate(parseFloat(e.target.value))}
              onMouseUp={generatePlayerState}
              onTouchEnd={generatePlayerState}
              onKeyUp={generatePlayerState}
            />
            <div className="label">Speed: {playbackRate}x</div>
          </div>
          <div>
            <div className={styles.playButton} onClick={() => {setPaused(!paused)}}>
              { paused && <FontAwesomeIcon className="ml-1" icon="play"/>}
              {!paused && <FontAwesomeIcon icon="pause"/>}
            </div>
          </div>
          <div className="lever">
            <input className="slider" type="range" min="0" max="100"
              value={volume}
              onChange={(e) => setVolume(parseFloat(e.target.value))}
              onMouseUp={generatePlayerState}
              onTouchEnd={generatePlayerState}
              onKeyUp={generatePlayerState}
            />
            <div className="label">Volume: {volume}</div>
          </div>
        </div>

        <div className={styles.seek}>
          <input className={styles.seekSlider} type="range" min="0"
            max={props.duration}
            value={currentTime}
            onChange={(e) => setNewTime(parseFloat(e.target.value))}
            onMouseDown={() => isPlayerTimeUpdate.current = true}
            onMouseUp={updateCurrentPlayerTime}
            onTouchStart={() => isPlayerTimeUpdate.current = true}
            onTouchEnd={updateCurrentPlayerTime}
            onKeyDown={() => isPlayerTimeUpdate.current = true}
            onKeyUp={updateCurrentPlayerTime}
          />
          <div className="label">
            <div>{beautifyTime(currentTime)}</div>
            <div>{beautifyTime(props.duration)}</div>
          </div>
        </div>

        {/* 
        <div className={styles.remotePlayerOptions}>

          <div>
            <div className="label mb-2">Sync playback with:</div>
            <div className={styles.radioItem}>
              <input type="radio" name="remotePlay" value="false" id="local" checked={!isRemotePlay} onChange={toggleRemotePlay} onClick={() => setIsRemotePlay(false)}/>
              <label  className={styles.radioLabel} htmlFor="local">my own microphone</label>
            </div>
            <div className={styles.radioItem}>
              <input type="radio" name="remotePlay" value="true" id="remote" checked={isRemotePlay} onChange={toggleRemotePlay} onClick={() => setIsRemotePlay(true)}/>
              <label  className={styles.radioLabel} htmlFor="remote">the student's microphone</label>
            </div>
          </div>

          <div className="lever">
            <div className={styles.balanceLabel}>Balance</div>
            <input className="slider" type="range" max="100" onMouseUp={(e) => (e.target.value)}/>
            <div className="label">
              <div>Mic</div>
              <div>MP3</div>
            </div>
          </div>

        </div>
        */}


      </div>
    </Draggable>
  )
}

export default AudioPlayer;