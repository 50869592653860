// react libs
import React, { useState, useRef, useEffect, useContext } from 'react';

// 3rd party react libs
import { withRouter, Prompt } from "react-router";
import { ProgressBar } from "react-bootstrap";

// 3rd party libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// melodisto components
import { UserContext } from "../App";
import { ToastContext } from "../App";
import { MusicStandContext } from "../App";
import NotesRaw from './NotesRaw';
import DocumentViewer from './DocumentViewer';
import VideoRecorder from './VideoRecorder';

// melodisto helper modules
import { DEBUG, projectAuth, projectFirestore, rtcPeerConfiguration, utilTimestamp, firebaseTimestamp, analytics, ANALYTICS_EVENTS, ANONYMOUS_EMAIL } from '../firebase/config';

// melodisto styles
import styles from './LessonScreen.module.css';

// global variables
const WEB_RTC_DEBUG = false;

const SIGNALS = {
  READY : 'ready',
  DESCRIPTION : 'description',
  OFFER : 'offer',
  ANSWER : 'answer',
  CANDIDATE : 'candidate',
  SHEET_MUSIC_UPDATED : 'sheet_music_updated',
  SHEET_MUSIC_CLOSED : 'sheet_music_closed',
  AUDIO_OPTIONS_SET : 'audio_options_set',
  REMOTE_PIANO_STATE : 'remote_piano_update',
  END_LESSON : 'end_lesson',
}
const COLLECTIONS = {
  TO_GUEST_SIGNALS : 'toGuestSignals',
  TO_HOST_SIGNALS : 'toHostSignals',
  TO_GUEST_CANDIDATES : 'toGuestCandidates',
  TO_HOST_CANDIDATES : 'toHostCandidates',
  TO_GUEST_EVENTS : 'toGuestEvents',
  TO_HOST_EVENTS :  'toHostEvents',
}
const DOC_NAMES = {
  READY_SIGNAL: 'ReadySignal',
  SESSION_DESCRIPTION: 'RTCSessionDescription',
  SHEET_MUSIC_SIGNALS: 'SheetMusicSignals',
  AUDIO_OPTIONS: 'AudioOptions',
  REMOTE_PIANO_STATE: 'remotePianoState',
  LESSON_STATE: 'lessonState',
}
const DOC_CLOSED_URL = 'signal:doc-closed';
// number of seconds after which a ready signal from the host is considered "too old"
const MAX_AGE_OF_READY_SIGNAL = 5;
// how many times we send the ready signal before we give up
const MAX_NUMBER_OF_ATTEMPTS = 60;

const LEAVE_SESSION_MESSAGE = "Are you sure you want to exit this call?";
const TEACHER_UNAVAILABLE_MESSAGE = "Looks like your teacher didn't join the lesson within the last " + 
  Math.ceil(MAX_AGE_OF_READY_SIGNAL * MAX_NUMBER_OF_ATTEMPTS / 60) + " minutes.\n\n" + 
  "Wanna keep trying? Press OK.\n";

function LessonScreen(props) {
  DEBUG.COMP_RENDER && console.log('<LessonScreen>');

  const [userContext] = useContext(UserContext);
  const [toast, setToast] = useContext(ToastContext);

  const localVideoRef = useRef();
  const remoteVideoThumbnailRef = useRef();
  const remoteVideoFullRef = useRef();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [chatRoomHeightOffset, setChatRoomHeightOffset] = useState('singleVideo');

  const [musicStand, setMusicStand] = useContext(MusicStandContext);
  const musicStandUrl = useRef(null);

  const [receivedReady, setReceivedReady] = useState(false);
  const receivedOffer = useRef(false);
  const [guestEmail, setGuestEmail] = useState(null);
  const sdpSender = useRef(null);
  const roomId = useRef(null); // from the URL
  const roomRef = useRef(null);
  const remoteIpAddress = useRef(null);

  const userMediaStream  = useRef(null);
  const remoteStream = useRef(null);

  const peerConnection = useRef(null);

  const readyTouter = useRef(null);
  // we need both, `readySignalCounter` and `readySignalCount` because we're using it in setIntervall()
  const readySignalCounter = useRef(1);
  const [readySignalCount, setReadySignalCount] = useState(1);

  const unsubscribeReadySignals = useRef(null);
  const unsubscribeDescription = useRef(null);
  const unsubscribeCandidates = useRef(null);
  const unsubscribeRoomEvents = useRef(null);

  const [isRecorderVisible, setIsRecorderVisible] = useState(false);

  // onMount and onUnmount
  useEffect(() => {
    props.setIsLessonScreenMounted(true);
    window.addEventListener('beforeunload', leavePageAlert);
    roomId.current = props.match.params.roomId.toLowerCase(); // from the URL
    roomRef.current = projectFirestore.collection('rooms').doc(props.match.params.roomId.toLowerCase());
  
    async function init() {
      await initializePeer({doReset : false});
    }
    init();

    // we need this because: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#potential-issues
    const localVideoRefCurrent = localVideoRef.current;

    analytics.logEvent(ANALYTICS_EVENTS.STARTED);

    // onUnmount
    return () => {
      // implicitly end the lesson
      sendSignal({lessonEnd: Date.now()}, SIGNALS.END_LESSON);

      clearInterval(readyTouter.current);
      props.setIsLessonScreenMounted(false);
      window.removeEventListener('beforeunload', leavePageAlert);
      hangUpHandler(localVideoRefCurrent);
      analytics.logEvent(ANALYTICS_EVENTS.ENDED);
    }
  }, []);

  useEffect(() => {
    // this also unmounts <NotesRaw>
    setGuestEmail(null);

    if (props.remoteEndLessonTimeStamp !== 0) {
      sendSignal({lessonEnd: Date.now()}, SIGNALS.END_LESSON);
      initializePeer({doReset : true});
    }

  }, [props.remoteEndLessonTimeStamp]);

  useEffect(() => {
    applyMediaConstraints();
  }, [props.mediaTrackConstraints]);

  useEffect(() => {
    sendSignal(props.remoteAudioOptions, SIGNALS.AUDIO_OPTIONS_SET)
  }, [props.remoteAudioOptions]);

  useEffect(() => {
    if (props.pianoData.sender === userContext.email) {
      sendSignal(props.pianoData, SIGNALS.REMOTE_PIANO_STATE)
    }
  }, [props.pianoData]);

  useEffect(() => {
    setIsFullScreen(false); // reset to normal view as soon as the music stand is updated

    if (!musicStand) {
      setChatRoomHeightOffset('singleVideo');
      const blankSignalDoc = {doc: {url: DOC_CLOSED_URL}, modifiedBy: userContext.email};
      sendSignal(blankSignalDoc, SIGNALS.SHEET_MUSIC_CLOSED);
      return;
    } else {
      setChatRoomHeightOffset('doubleVideo');

      // log the the current doc to the activity history but only if music stand displays a new doc
      if (props.connectionStatus === 'connected' && musicStandUrl.current !== musicStand.doc.url) {
        props.appendDocUsed(sdpSender.current, musicStand.doc);
      }
      musicStandUrl.current = musicStand.doc.url;
    }

    if (musicStand.modifiedBy === userContext.email) {
      sendSignal(musicStand, SIGNALS.SHEET_MUSIC_UPDATED);
      return;
    }

  }, [musicStand, musicStandUrl.current]);

  useEffect(() => {
    if (receivedReady === true) {
      console.log("received READY; instigating call...");
      instigateConnection();
    }
  }, [receivedReady]);

  const initializePeer = async (options) => {

    if (options?.doReset) {
      destroyPeerConnection();
      remoteStream.current = null;
      remoteVideoFullRef.current.srcObject = null;
      remoteVideoThumbnailRef.current.srcObject = null;
    }

    await createPeerConnection();
    const userMediaError = await openUserMedia(options);
    if (userMediaError) {
      setReadySignalCount(MAX_NUMBER_OF_ATTEMPTS + 100); // prevent the extra <Prompt> to be displayed
      if (userMediaError.name === 'NotAllowedError') {
        alert("Please grant Melodisto access to your camera and microphone. Then try again.");
      } else if (userMediaError.name === 'OverconstrainedError') {
        alert(userMediaError.name + ": Your browser doesn't support the required audio/video settings.");
      } else {
        alert(userMediaError.name + " Your browser is not supported.");
      }
      props.history.push('/');
      return;
    }

    userMediaStream.current.getTracks().forEach(track => {
      peerConnection.current.addTrack(track, userMediaStream.current);
    });
    peerConnection.current.addTrack(props.mp3AudioTrack, userMediaStream.current);

    if (isUserHost()) {

      acceptConnection();
      sendSignal(0, SIGNALS.READY); // initial signal, so the Guest doesn't have to wait an extra n seconds

      // Host: periodically send a READY signal until you receive an OFFER
      readyTouter.current = setInterval(() => {
        sendSignal(readySignalCounter.current, SIGNALS.READY);
        readySignalCounter.current++;
        setReadySignalCount(count => count+1);

        if (readySignalCounter.current > MAX_NUMBER_OF_ATTEMPTS) {
          const keepTrying = window.confirm(TEACHER_UNAVAILABLE_MESSAGE);
          if (keepTrying) {
            analytics.logEvent(ANALYTICS_EVENTS.RETRIED);
            readySignalCounter.current = 0;
            setReadySignalCount(0);
          } else {
            clearInterval(readyTouter.current);
            analytics.logEvent(ANALYTICS_EVENTS.TIMED_OUT);
            props.history.push('/');
          }
        }

        if (receivedOffer.current) {
          clearInterval(readyTouter.current);
        }
      }, 1000 * MAX_AGE_OF_READY_SIGNAL);

    } else {
      setGuestEmail(userContext.email);
      // updates `receivedReady` so useEffect can instigate the connection
      listenForReadySignal();
    }
  }

  // used by the GUEST after the HOST ringed them
  const instigateConnection = async () => {
    listenForAnswer();
    listenForCandidates();

    // send any ice candidates to the other peer
    // this is triggered by peerConnection.current.setLocalDescription()
    peerConnection.current.onicecandidate = async (event) => {
      if (event.candidate) {
        await sendSignal({candidate: event.candidate.toJSON()}, SIGNALS.CANDIDATE);
      } else {
        // Done sending ICE Candidates
      }
    }

    const offer = await peerConnection.current.createOffer();
    await peerConnection.current.setLocalDescription(offer);
    // not supported by Firefox: await sendSignal(offer.toJSON(), 'offer');
    await sendSignal({
      type: offer.type, 
      sdp: offer.sdp, 
      sdpSender: {name: userContext.displayName ? userContext.displayName : 'Unknown', email: userContext.email},
    }, SIGNALS.OFFER);

    peerConnection.current.addEventListener('track', event => {
      WEB_RTC_DEBUG && console.log('Got remote track:', event.streams[0]);
      event.streams[0].getTracks().forEach(track => {
        remoteStream.current.addTrack(track);
      });
    });
  }

  // used by the HOST
  const acceptConnection = async () => {
    listenForOffer();
    listenForCandidates();

    if (!peerConnection.current) return;

    // send any ice candidates to the other peer
    // this is triggered by peerConnection.current.setLocalDescription()
    peerConnection.current.onicecandidate = async (event) => {
      if (event.candidate) {
        await sendSignal({candidate: event.candidate.toJSON()}, SIGNALS.CANDIDATE);
      } else {
        WEB_RTC_DEBUG && console.log("Done sending ICE Candidates");
      }
    }

    peerConnection.current.addEventListener('track', event => {
      WEB_RTC_DEBUG && console.log('Got remote track:', event.streams[0]);
      event.streams[0].getTracks().forEach(track => {
        remoteStream.current.addTrack(track);
      });
    });
  }

  // GUEST is listening for answers from the HOST
  const listenForAnswer = () => {
    // listen to a special doc in the signals collection called 'RTCSessionDescription'
    let unsubscribeDescription_ = roomRef.current.collection(COLLECTIONS.TO_GUEST_SIGNALS).doc(DOC_NAMES.SESSION_DESCRIPTION).onSnapshot(async (snapshot) => {
      const data = snapshot.data();
      if (peerConnection.current && !peerConnection.current.currentRemoteDescription && data) {
        console.log('💓 answer', data);
        sdpSender.current = data.sdpSender;
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(data));
      }
    });
    unsubscribeDescription.current = unsubscribeDescription_;
  }

  // listen for answers from the HOST or GUEST
  const listenForCandidates = () => {
    // listen to the respective candidates collection
    const iceCandidatesCollection = isUserHost() ? COLLECTIONS.TO_HOST_CANDIDATES : COLLECTIONS.TO_GUEST_CANDIDATES;
    let unsubscribeCandidates_ = roomRef.current.collection(iceCandidatesCollection).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(async change => {
        if (peerConnection.current && change.type === 'added') {
          let data = change.doc.data();
          console.log("💓 candidate", data);
          await peerConnection.current.addIceCandidate(new RTCIceCandidate(data.candidate));
        }
      });
    });
    unsubscribeCandidates.current = unsubscribeCandidates_;
  }

  // HOST is listening for offers from a GUEST and sends an answer
  const listenForOffer = () => {
    // listen to a special doc in the signals collection called 'RTCSessionDescription'
    let unsubscribeDescription_ = roomRef.current.collection(COLLECTIONS.TO_HOST_SIGNALS).doc(DOC_NAMES.SESSION_DESCRIPTION).onSnapshot(async (snapshot) => {
      const data = snapshot.data();
      if (peerConnection.current && data && data.type) {
        console.log('💓', data);
        receivedOffer.current = true;
        sdpSender.current = data.sdpSender;
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(data));
        const answer = await peerConnection.current.createAnswer();
        await peerConnection.current.setLocalDescription(answer);
        // not supported by Firefox is: await sendSignal(answer.toJSON(), 'answer');
        await sendSignal({
          type: answer.type,
          sdp: answer.sdp,
          sdpSender: {name: userContext.displayName, email: userContext.email},
        }, SIGNALS.ANSWER);
      }
    });
    unsubscribeDescription.current = unsubscribeDescription_;
  }

  // GUEST is listening for ready signals from the HOST
  const listenForReadySignal = () => {
    const pulseArray = [];

    let unsubscribeReadySignals_ = roomRef.current.collection(COLLECTIONS.TO_GUEST_SIGNALS).doc(DOC_NAMES.READY_SIGNAL)
    .onSnapshot((doc) => {
      doc?.data()?.timeStamp.seconds && pulseArray.push(doc?.data()?.timeStamp.seconds);

      if (pulseArray.length > 1) {
        const lastPulseFrequency = pulseArray[pulseArray.length - 1] - pulseArray[pulseArray.length - 2];
        console.log(pulseArray, lastPulseFrequency);
        if (lastPulseFrequency > MAX_AGE_OF_READY_SIGNAL - 2 && lastPulseFrequency < MAX_AGE_OF_READY_SIGNAL + 2) {
          setReceivedReady(true);
        } else {
          console.log("READY signal too old");
        }
      }
    });
    unsubscribeReadySignals.current = unsubscribeReadySignals_;
  }

  // BOTH are listening for room events (doc opened, area selected, metronome activated, etc.)
  const listenRoomEvents = () => {
    const roomEventsCollection = isUserHost() ? COLLECTIONS.TO_HOST_EVENTS : COLLECTIONS.TO_GUEST_EVENTS;

    // listen for any doc in the signals collection .doc(DOC_NAMES.SHEET_MUSIC_SIGNALS)
    let unsubscribeRoomEvents_ = roomRef.current.collection(roomEventsCollection).onSnapshot(async (snapshot) => {
      snapshot.docChanges().forEach(async change => {
        if (!change.doc || !change.doc.data()) return;
        console.log("💓", change.doc.data())
        if (change.doc.id === DOC_NAMES.SHEET_MUSIC_SIGNALS) {
          handleSheetMusicUpdate(change.doc.data());
        }
        if (change.doc.id === DOC_NAMES.AUDIO_OPTIONS) {
          handleAudioOptionsUpdate(change.doc.data());
        }
        if (change.doc.id === DOC_NAMES.REMOTE_PIANO_STATE) {
          handlePianoUpdate(change.doc.data());
        }
        if (change.doc.id === DOC_NAMES.LESSON_STATE) {
          remoteEndLesson();
        }
      })
    });
    unsubscribeRoomEvents.current = unsubscribeRoomEvents_;
  }

  const handlePianoUpdate = (data) => {
    props.setPianoData(data);
  }

  const handleSheetMusicUpdate = (data) => {
    if (data.modifiedBy === userContext.email) return;
    if (data.doc.url === DOC_CLOSED_URL) {
      setMusicStand(null);
      return;
    }
    setMusicStand(data);  
  }

  const handleAudioOptionsUpdate = (data) => {
    const newConstraints = {
      ...props.mediaTrackConstraints,
      audio: {
        ...props.mediaTrackConstraints.audio,
        ...data
      }
    }
    props.updateMediaTrackConstraints(newConstraints)
  }

  const openUserMedia = async (options) => {

    // only call getUserMedia() once when the component mounts
    // but not when we are just resetting it in the "re-join" scenario
    if (options?.doReset === false) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(props.mediaTrackConstraints);
        localVideoRef.current.srcObject = stream; // hook up the stream from local camera/mic with the <video> element
        userMediaStream.current = stream; // hook up the stream from local camera/mic with what we stream into RTCPeerConnection
      } catch(e) {
        return e;
      }
    }
    remoteStream.current = new MediaStream();
    remoteVideoFullRef.current.srcObject = remoteStream.current;
    remoteVideoThumbnailRef.current.srcObject = remoteStream.current;
    return null;
  }

  const createPeerConnection = async () => {
    let pc = new RTCPeerConnection(rtcPeerConfiguration);
    WEB_RTC_DEBUG && console.log("@@*** ICE connection state is " + pc.iceConnectionState)
    WEB_RTC_DEBUG && console.log("@@+++ WebRTC signaling state is: " + pc.signalingState);
    WEB_RTC_DEBUG && console.log("@@+++ WebRTC connection state is: " + pc.connectionState);
    props.updateConnectionStatus(pc.iceConnectionState);

    const handleConnectionStateChangeEvent = (event) => {
      WEB_RTC_DEBUG && console.log("@@+++ WebRTC connection state changed to: ", pc.connectionState);
    }

    const handleSignalingStateChangeEvent = (event) => {
      WEB_RTC_DEBUG && console.log("@@--- WebRTC signaling state changed to: ", pc.signalingState);
    }
  
    const handleICEConnectionStateChangeEvent = (event) => {
      WEB_RTC_DEBUG && console.log("@@*** ICE connection state changed to: ", pc.iceConnectionState);
      props.updateConnectionStatus(pc.iceConnectionState);
      if (pc.iceConnectionState === "connected") {
        analytics.logEvent(ANALYTICS_EVENTS.CONNECTED);
        connectionEstablishedHandler();
      }
      if (pc.iceConnectionState === "disconnected") {
        analytics.logEvent(ANALYTICS_EVENTS.DISCONNECTED);
        initializePeer({doReset : true});
      }
    }

    const handleIceCandidateEvent = (event) => {
    }

    const handleonICEgatheringStateChangeEvent = (event) => {
    }
  
    pc.onicegatheringstatechange = handleonICEgatheringStateChangeEvent;
    pc.oniceconnectionstatechange = handleICEConnectionStateChangeEvent;
    pc.onsignalingstatechange = handleSignalingStateChangeEvent;
    pc.onicecandidate = handleIceCandidateEvent;
    pc.onconnectionstatechange = handleConnectionStateChangeEvent;
    peerConnection.current = pc;
  }

  const destroyPeerConnection = () => {
    if (!peerConnection.current) return

    // kill event handlers
    peerConnection.current.onicegatheringstatechange = null;
    peerConnection.current.oniceconnectionstatechange = null;
    peerConnection.current.onsignalingstatechange = null;
    peerConnection.current.onicecandidate = null;
    peerConnection.current.onconnectionstatechange = null;
    // close the peerConnection
    peerConnection.current.close();
    // nullify peerConnection
    peerConnection.current = null;
  }

  const sendSignal = async (signal, type) => {
    // if you are the host, you are posting to the "toGuestSignals" channel (a.k.a. collection)
    const signalsCollection = isUserHost() ? COLLECTIONS.TO_GUEST_SIGNALS : COLLECTIONS.TO_HOST_SIGNALS;
    const iceCandidatesCollection = isUserHost() ? COLLECTIONS.TO_GUEST_CANDIDATES :  COLLECTIONS.TO_HOST_CANDIDATES;
    const roomEventsCollection = isUserHost() ? COLLECTIONS.TO_GUEST_EVENTS :  COLLECTIONS.TO_HOST_EVENTS;

    if (type === SIGNALS.READY) {
      console.log("🔥", type,  signal);
      roomRef.current.collection(signalsCollection).doc(DOC_NAMES.READY_SIGNAL).set({
        type : SIGNALS.READY,
        timeStamp: utilTimestamp(),
        numberOfAttempts: signal,
      });
      return
    }

    if (type === SIGNALS.DESCRIPTION || type === SIGNALS.OFFER || type === SIGNALS.ANSWER) {
      console.log("🔥", type,  signal);
      roomRef.current.collection(signalsCollection).doc(DOC_NAMES.SESSION_DESCRIPTION).set(signal);
      return
    }

    if (type === SIGNALS.CANDIDATE) {
      if (!remoteIpAddress.current) {
        setRemoteIpAddress(signal.candidate.candidate);
      }
      console.log("🔥", type,  signal);
      roomRef.current.collection(iceCandidatesCollection).add(signal);
      return
    }

    if (type === SIGNALS.AUDIO_OPTIONS_SET && props.connectionStatus === 'connected') {
      console.log("🔥", type,  signal);
      roomRef.current.collection(roomEventsCollection).doc(DOC_NAMES.AUDIO_OPTIONS).set(signal);
      return
    }

    if (type === SIGNALS.END_LESSON && isUserHost()) {
      console.log("🔥", type,  signal);
      roomRef.current.collection(roomEventsCollection).doc(DOC_NAMES.LESSON_STATE).set(signal);
      return
    }

    if (type === SIGNALS.REMOTE_PIANO_STATE && props.connectionStatus === 'connected') {
      console.log("🔥", type,  signal);
      roomRef.current.collection(roomEventsCollection).doc(DOC_NAMES.REMOTE_PIANO_STATE).set(signal);
      return
    }

    if (type === SIGNALS.SHEET_MUSIC_UPDATED && props.connectionStatus === 'connected') {
      console.log("🔥", type,  signal);
      roomRef.current.collection(roomEventsCollection).doc(DOC_NAMES.SHEET_MUSIC_SIGNALS).set(signal);
      return
    }

    if (type === SIGNALS.SHEET_MUSIC_CLOSED && roomRef && props.connectionStatus === 'connected') {
      console.log("🔥", type,  signal);
      // set both docs to `blankSignalDoc` because either party could have closed the current doc
      roomRef.current.collection(COLLECTIONS.TO_GUEST_EVENTS).doc(DOC_NAMES.SHEET_MUSIC_SIGNALS).set(signal);
      roomRef.current.collection(COLLECTIONS.TO_HOST_EVENTS).doc(DOC_NAMES.SHEET_MUSIC_SIGNALS).set(signal);
      return
    }
  }

  const unsubscribeSignals = () => {
    // these might still be null - hence the "?."
    unsubscribeReadySignals?.current?.();
    unsubscribeDescription?.current?.();
    unsubscribeCandidates?.current?.();
  }

  const deleteToGuestSignals = async () => {
    if (!roomRef.current) return;

    const toGuestCandidates = await roomRef.current.collection(COLLECTIONS.TO_GUEST_CANDIDATES).get();
    const proms1 = toGuestCandidates.docs.map(doc => doc.ref.delete());
    await Promise.all(proms1);

    const toGuestSignals = await roomRef.current.collection(COLLECTIONS.TO_GUEST_SIGNALS).get();
    const proms2 = toGuestSignals.docs.map(doc => doc.ref.delete());
    await Promise.all(proms2);

    const toGuestEvents = await roomRef.current.collection(COLLECTIONS.TO_GUEST_EVENTS).get();
    const proms3 = toGuestEvents.docs.map(doc => doc.ref.delete());
    await Promise.all(proms3);
  }

  const deleteToHostSignals = async () => {
    if (!roomRef.current) return;

    const toHostCandidates = await roomRef.current.collection(COLLECTIONS.TO_HOST_CANDIDATES).get();
    const proms1 = toHostCandidates.docs.map(doc => doc.ref.delete());
    await Promise.all(proms1);

    const toHostSignals = await roomRef.current.collection(COLLECTIONS.TO_HOST_SIGNALS).get();
    const proms2 = toHostSignals.docs.map(doc => doc.ref.delete());
    await Promise.all(proms2);

    const toHostEvents = await roomRef.current.collection(COLLECTIONS.TO_HOST_EVENTS).get();
    const proms3 = toHostEvents.docs.map(doc => doc.ref.delete());
    await Promise.all(proms3);
  }

  const connectionEstablishedHandler = () => {

    unsubscribeSignals();

    readySignalCounter.current = 1;
    setReadySignalCount(1);

    setMusicStand(null);

    if (isUserHost()) {
      whoJoinedNotification();
      setGuestEmail(sdpSender.current.email);
      if (sdpSender.current.email !== ANONYMOUS_EMAIL) {
        props.logLesson(sdpSender.current, remoteIpAddress.current);
      }
    } else {
      if (props.isLoggedIn) {
        // log the lesson 15 seconds later than the host to avoid a dupe based on a race condition
        setTimeout(() => {props.logLesson(sdpSender.current) }, 5000);
      }
    }

    clearInterval(readyTouter.current);

    // reset these for a "rejoin" scenario
    setReceivedReady(false);
    receivedOffer.current = false;

    // wait a bit before listening so we don't pick up the END_LESSON signal from a previous lesson
    setTimeout(() => listenRoomEvents(), 2000);

    if (isUserHost()) {
      deleteToHostSignals();
    } else {
      deleteToGuestSignals();
    }
  }

  const hangUpHandler = (localVideoRefCopy) => {
    props.updateConnectionStatus(null);

    // stop the camera and mic
    if (localVideoRefCopy) {
      localVideoRefCopy.srcObject?.getTracks().forEach(track => {
        track.stop();
      });
    }

    clearInterval(readyTouter.current);

    unsubscribeSignals();
    unsubscribeRoomEvents?.current?.();

    destroyPeerConnection();
    deleteToGuestSignals();
    deleteToHostSignals();

    setMusicStand(null);

  }

  const leavePageAlert = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  const applyMediaConstraints = async () => {
    if (!userMediaStream.current) return;

    userMediaStream.current.getTracks().forEach(track => {
      track.stop();
    });
    userMediaStream.current = null;
    
    try {
      const stream = await navigator.mediaDevices.getUserMedia(props.mediaTrackConstraints);
      localVideoRef.current.srcObject = stream; // hook up the stream from local camera/mic with the <video> element
      userMediaStream.current = stream; // hook up the stream from local camera/mic with what we stream into RTCPeerConnection

      const currentTracks = stream.getTracks();
      peerConnection.current.getSenders().forEach(sender => {
        const newTrack = currentTracks.find(track => (
          track.kind === sender.track.kind &&
          sender.track.label !== 'MediaStreamAudioDestinationNode' // exclude the mp3AudioTrack (Chrome and Safari)
          && sender.track.label !== '' // exclude the mp3AudioTrack (Firefox)
        ));
        if (newTrack) {
          sender.replaceTrack(newTrack);
        } else {
          sender.replaceTrack(props.mp3AudioTrack);
        }
      });
    } catch(e) {
      alert(e.name + ': ' + e.message)
    }
  }

  const toggleMicrophone = () => {
    userMediaStream.current.getAudioTracks()[0].enabled = !(userMediaStream.current.getAudioTracks()[0].enabled);
  }

  const isUserHost = () => {
    // if this is your room, you are the host
    return roomId.current === projectAuth.currentUser?.email;
  }

  const setRemoteIpAddress = (candidate) => {
    const candidateFields = candidate.split(' ');
    if (candidateFields[4] && candidateFields[4].split('.').length === 4) {
      remoteIpAddress.current = candidateFields[4].split(':')[0];
    }
  }

  const whoJoinedNotification = () => {
    if (sdpSender.current.email === ANONYMOUS_EMAIL) {
      setToast("The student is not logged in. No lesson notes and no sharing, unfortunately.");
    }
  }

  const remoteEndLesson = () => {
    // prevent the Prompt
    setReadySignalCount(MAX_NUMBER_OF_ATTEMPTS + 100);

    setToast("Your teacher has ended this lesson.");
    setTimeout(() => {
      props.history.push('/');
    }, 700);
  }

  return (
    <div className="mainScreen fadein">
      <Prompt when={readySignalCount <= MAX_NUMBER_OF_ATTEMPTS} message={() => LEAVE_SESSION_MESSAGE}/>

      <div className={styles.silverScreen}>

        {/* this is hidden unless a document is opened */}
        {
          (musicStand !== null) && (
            <div className={styles.musicStandContainer}>
              <DocumentViewer
                roomId={roomId.current}
                connectionStatus={props.connectionStatus}
                guestEmail={guestEmail}
                appendDocUsed={props.appendDocUsed}
                shareDocument={props.shareDocument}
              />
            </div>
          )
        }

        {/* full size remote video; only visible if music stand is blank */}
        <VideoScreen
          videoRef={remoteVideoFullRef}
          className={styles.largeVideo}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          isMuted={false}
          isVisible={musicStand === null}
          connectionStatus={props.connectionStatus}
          now={isUserHost() ? readySignalCount/MAX_NUMBER_OF_ATTEMPTS * 100 : 100}
          doShowMediaSettingsModal={props.doShowMediaSettingsModal}
          roomId={roomId.current}
          isUserHost={isUserHost()}
        />

      </div>

      <div className={styles.sideBar}>

          {/* video recorder */}
          <VideoRecorder
            setIsRecording={props.setIsRecording}
            setIsRecorderVisible={setIsRecorderVisible}
            mediaTrackConstraints={props.mediaTrackConstraints}
            recorderTimeStamp={props.recorderTimeStamp}
            otherParty={sdpSender.current}
            shareDocument={props.shareDocument}
            appendDocUsed={props.appendDocUsed}
          />

          {/* local video */}
          <div>
            <VideoScreen
              videoRef={localVideoRef}
              className={styles.smallVideo}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              isMuted={true}
              isVisible={!props.recordingClicked}
              connectionStatus='connected'
              doShowMediaSettingsModal={props.doShowMediaSettingsModal}
              toggleMicrophone={toggleMicrophone}
              roomId={roomId.current}
              isUserHost={isUserHost()}
            />
          </div>

          {/* thumbnail size remote video; only visible if music stand is NOT blank */}
          <div>
            <VideoScreen
              videoRef={remoteVideoThumbnailRef}
              className={styles.smallVideo}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              isMuted={false}
              isVisible={musicStand !== null}
              connectionStatus={props.connectionStatus}
              now={isUserHost() ? readySignalCount/MAX_NUMBER_OF_ATTEMPTS * 100 : 100}
              doShowMediaSettingsModal={props.doShowMediaSettingsModal}
              roomId={roomId.current}
              isUserHost={isUserHost()}
            />
          </div>

          {/* Notes */}
          <div className="overflow-hidden mb-1">
            {roomId && guestEmail && sdpSender.current?.email !== ANONYMOUS_EMAIL &&
              <NotesRaw
                isLoggedIn={props.isLoggedIn}
                chatRoomHeightOffset={chatRoomHeightOffset}
                roomId={roomId.current}
                guestEmail={guestEmail}
                activityLog={props.activityLog}
                connectionStatus={props.connectionStatus}
                openDocument={props.openDocument}
              />
            }
          </div>

      </div>
    </div>
  )  
}

function VideoScreen(props) {
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(true);

  const toggleMicrophone = () => {
    if (!props.isMuted) return; // user can only mute the local mic (local or not is determined by props.isMuted)
    props.toggleMicrophone();
    setIsMicrophoneEnabled(!isMicrophoneEnabled);
  }

  const semiTransStyle = {
    zIndex: 12,
    opacity: .5
  };

  const fullScreenVideoStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    maxHeight: '100vh',
    transform: props.isMuted ? 'scale(-1, 1)' : 'scale(1, 1)'
  }

  return (
    <div className={props.isVisible ? '' : 'invisible'} style={props.isFullScreen && props.className !== styles.largeVideo ? semiTransStyle : {}}>

      <div className={props.connectionStatus !== 'connected' ? 'invisible' : ''} onDoubleClick={toggleMicrophone}>

        {/* only the local video has `isMuted=true`; let's use this to determine whether it's the local video */}
        {props.isMuted &&
          <div className={styles.controlsContainer}>
            <div className={styles.mediaSettingsButton} onClick={props.doShowMediaSettingsModal} title="Select Microphone and Camera">
              <FontAwesomeIcon className="fa-button" icon="cog"/>
            </div>
            <div className={styles.microphoneButton} onClick={toggleMicrophone}>
              <FontAwesomeIcon className="fa-button" icon={isMicrophoneEnabled ? "microphone-alt" : "microphone-alt-slash"} title="Mute/Unmute"/>
            </div>
          </div>
        }
        <video
          className={props.className}
          style={props.isFullScreen && props.className === styles.largeVideo ? fullScreenVideoStyles : {transform: props.isMuted ? 'scale(-1, 1)' : 'scale(1, 1)'}}
          ref={props.videoRef}
          autoPlay={true}
          playsInline={true}
          muted={props.isMuted}
        />
        {props.className === styles.largeVideo && props.connectionStatus === 'connected' &&
          <div className={styles.modeButton} onClick={() => props.setIsFullScreen(!props.isFullScreen)} role="button">
            {!props.isFullScreen && <FontAwesomeIcon className="fa-button" icon="expand-alt"   title="Full Screen"/>}
            {props.isFullScreen  && <FontAwesomeIcon className="fa-button" icon="compress-alt" title="Normal Screen"/>}
          </div>
        }
      </div>
      
      {props.connectionStatus !== 'connected' &&
        <div className={styles.waiting}>
          <div className={styles.waitingMessage1}>
            {!props.isUserHost && "Calling " + props.roomId}
          </div>
          <div className={styles.waitingMessage2}>
            {props.isUserHost ? "Waiting for a student to join..." : "Waiting for your teacher to join..."}
          </div>
          <ProgressBar
            animated={true}
            variant={"primary"}
            now={props.now}
          />
        </div>
      }

    </div>
  )
}

export default withRouter(LessonScreen);