// react libs
import React, { useState, useEffect, createContext, useRef, useMemo } from 'react';
import ReactDOM from "react-dom"

// 3rd party react libs
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Modal } from 'react-bootstrap';

// 3rd party libs
import { library } from '@fortawesome/fontawesome-svg-core';
//// add all the icon names you ever use in these two lines
import { faShare, faSignOutAlt, faTrashAlt, faTimesCircle, faArrowLeft, faFileUpload, faPrint, faShareAlt, faFileDownload,
  faCog, faFolderOpen, faSearchPlus, faSearchMinus, faExpandAlt, faCompressAlt, faMicrophoneAlt, faMicrophoneAltSlash,
  faFilePdf, faVideo, faVolumeUp, faVolumeOff, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import Toast from "react-bootstrap/Toast"

// melodisto components
import DraggableModal from './components/DraggableModal.js';
import SignInScreen from './components/SignInScreen';
import Controls from './components/Controls';
import LessonScreen from './components/LessonScreen';
import HomeScreen from './components/HomeScreen';
import MediaSettings from './components/MediaSettings';
import AudioOptions from './components/AudioOptions';
import RemotePiano from './components/RemotePiano';
import AudioPlayer from './components/AudioPlayer';
import UserProfile from './components/UserProfile';

// melodisto helper modules
import { DEBUG, projectAuth, projectFirestore, projectStorage, firebaseTimestamp, utilTimestamp, ACTIVITIES_LOCATION, FILE_STORAGE_LOCATION,
  ACTIVITY_TYPES, isDocSharable, createPairId, DEFAULT_MEDIA_TRACK_CONSTRAINTS, DEFAULT_REMOTE_AUDIO_OPTIONS, anaylzeBrowser, ANONYMOUS_EMAIL } from './firebase/config';

// melodisto styles

// global variables
const LAST_ACTIVTY_HOURS_AGO = 12;

library.add(faShare, faSignOutAlt, faTrashAlt, faTimesCircle, faArrowLeft, faFileUpload, faPrint, faShareAlt, faFileDownload,
  faCog, faFolderOpen, faSearchPlus, faSearchMinus, faExpandAlt, faCompressAlt, faMicrophoneAlt, faMicrophoneAltSlash,
  faFilePdf, faVideo, faVolumeUp, faVolumeOff, faPause, faPlay);

export const MusicStandContext = createContext();
export const UserContext = createContext();
export const ToastContext = createContext();

function App() {
  DEBUG.COMP_RENDER && console.log('<App>');

  const [userContext, setUserContext] = useState({type: 'student', email: ANONYMOUS_EMAIL});
  const [user] = useAuthState(projectAuth);
  // TODO: this is currently not a real indicator; it's just time based
  // we need this b/c: LessonScreen.isUserHost() wasn't working if a teacher joins directly from the link
  const [userAuthCompleted, setUserAuthCompleted] = useState(false);

  const [musicStand, setMusicStand] = useState(null);
  const [toast, setToast] = useState(null);
  const [toastContent, setToastContent] = useState(null);
  const toastTimeOut = useRef(null);

  const [activityLog, setActivityLog] = useState(null); // activityLog is of type DocumentReference
  const [hasUserProfile, setHasUserProfile] = useState(true);
  const [isLessonScreenMounted, setIsLessonScreenMounted] = useState(false);
  const [showMediaSettingsModal, setShowMediaSettingsModal] = useState(false);
  const [mediaTrackConstraints, setMediaTrackConstraints] = useState(DEFAULT_MEDIA_TRACK_CONSTRAINTS);
  const [showAudioOptionsModal, setShowAudioOptionsModal] = useState(false);
  const [remoteAudioOptions, setRemoteAudioOptions] = useState(DEFAULT_REMOTE_AUDIO_OPTIONS);
  const [recorderTimeStamp, setRecorderTimeStamp] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingClicked, setRecordingClicked] = useState(false);
  const [remoteEndLessonTimeStamp, setRemoteEndLessonTimeStamp] = useState(0);

  const currentBrowser = useRef(anaylzeBrowser());

  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const replayVideoRef = useRef(null);
  const [videoPlaybackRate, setVideoPlaybackRate] = useState(1);

  const audioContext = useRef();
  const mp3AudioTrack = useRef();
  const remoteControlledPlayerRef = useRef(null);
  const [remoteControlledPlayerState, setRemoteControlledPlayerState] = useState({volume: 1, currentTime: 0, playbackRate: 1, paused: true});
  const [showAudioPlayerModal, setShowAudioPlayerModal] = useState(false);
  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
  const [playerDuration, setPlayerDuration] = useState(0);
  const recentAudioUrl = useRef();

  const [fileName, setFileName] = useState('');  
  const [pianoData, setPianoData] = useState({show: false, sender: null, activeKeys: [], displayNotes: [], keySignature: 'C', mode: 'sequence'});

  // "connectionStatus"  is mirroring PeerConnection.iceConnectionState
  // `new`/`checking`: Not at connected
  // `connected`/`completed`: Media path is available
  // `disconnected`/`failed`: Media path is not available (whatever data you are sending on data channel won't reach other end)
  const [connectionStatus, setConnectionStatus] = useState(null);

  const toastContext = useMemo(
    () => ([toast, setToast]), 
    [toast]
  );

  useEffect(() => {
    initRemoteControlledPlayer();
    setToast("Loading...");
    setTimeout(() => {
      setUserAuthCompleted(true);
      setToast(0);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!remoteControlledPlayerRef.current) return;

    if (remoteControlledPlayerState.paused) {
      remoteControlledPlayerRef.current.pause();
    } else {
      remoteControlledPlayerRef.current.play();
    }
    remoteControlledPlayerRef.current.volume = remoteControlledPlayerState.volume;
    remoteControlledPlayerRef.current.playbackRate = remoteControlledPlayerState.playbackRate;
  
  }, [remoteControlledPlayerState]);

  useEffect(() => {
    if (!replayVideoRef.current) return;
    replayVideoRef.current.playbackRate = videoPlaybackRate;
  }, [videoPlaybackRate]);

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const doc = await projectFirestore.collection('users').doc(projectAuth.currentUser.email.toLocaleLowerCase()).get();
        if (doc.exists && doc.data().myProfile) {
          setUserContext({...doc.data(), email: projectAuth.currentUser.email.toLocaleLowerCase()});

          if (!currentBrowser.current.isSafari) {
            findDeviceIdByName(doc.data().preferredAudioInput, doc.data().preferredVideoInput)
            .then((deviceIds) => {
              setPreferredDevice(deviceIds);
            });
          }

        } else {
          console.log("No myProfile for: " + projectAuth.currentUser.email.toLocaleLowerCase());
          setHasUserProfile(false);
        }
      } catch(error) {
        console.log("Error fetching user profile:", error);
      }
    }
    user && fetchUserProfile();
  }, [user]);

  useEffect(() => {
    if (userContext.myProfile) {
      setHasUserProfile(true);
    }
  }, [userContext]);

  useEffect(() => {
    clearTimeout(toastTimeOut.current);
    if (toast === null) return;

    if (toast !== null) {
      setToastContent(toast);
      // clear the toast after a few seconds in case a module forgets to clear it
      toastTimeOut.current = setTimeout(() => {
        setToastContent(null);
        setToast(null);
      }, 5000);
    } else {
      if (toast === 0) {
        // if a module set toast to 0, it wants it to disapear with no delay
        setToastContent(null);
        setToast(null);
      } else {
        // clear the toast 2 secs after a module cleared it
        toastTimeOut.current = setTimeout(() => {
          setToastContent(null);
          setToast(null);
        }, 2000);
        }
    }
  }, [toast])

  const findDeviceIdByName = async (deviceLabelAudio, deviceLabelVideo) => {
    const deviceIds = {audio: null, video: null}
    if (!deviceLabelAudio && !deviceLabelVideo) return deviceIds;

    const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const cannonicalLabel = deviceInfo.label.split(' ').filter((word) => RegExp(/^[A-Za-z]/).test(word)).join(' ');
      if (deviceInfo.kind === 'audioinput' && deviceLabelAudio === cannonicalLabel) {
        console.log('Prefered Audiom Device: ', deviceLabelAudio, deviceInfo.deviceId);
        deviceIds.audio = deviceInfo.deviceId;
      }
      if (deviceInfo.kind === 'videoinput' && deviceLabelVideo === cannonicalLabel) {
        console.log('Preferred Video Device: ', deviceLabelVideo, deviceInfo.deviceId);
        deviceIds.video = deviceInfo.deviceId;
      }
    }
    return deviceIds;
  }

  const setPreferredDevice = (deviceIds) => {
    const audioDeviceId = deviceIds.audio ? {exact: [deviceIds.audio]} : 'default';
    const videoDeviceId = deviceIds.video ? {exact: [deviceIds.video]} : 'default';
    console.log(DEFAULT_MEDIA_TRACK_CONSTRAINTS);
    updateMediaTrackConstraints({
      ...mediaTrackConstraints,
      audio: {
        ...DEFAULT_MEDIA_TRACK_CONSTRAINTS.audio,
        deviceId: audioDeviceId,
      },
      audioDeviceId: deviceIds.audio,
      video: {
        ...DEFAULT_MEDIA_TRACK_CONSTRAINTS.video,
        deviceId: videoDeviceId,
      },
      videoDeviceId: deviceIds.video
    });
  }

  const updateConnectionStatus = (status) => {
    // deal with a Safari anomaly which uses 'completed' instead of 'connected'
    // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/iceConnectionState#value
    if (status === 'completed') {
      setConnectionStatus('connected');
      return;
    }
    setConnectionStatus(status);
  }

  const updateMyStudents = (otherParty) => {
    let myStudent = {}
    myStudent[otherParty.email] = otherParty.name;
    const userRecord = {...userContext, myStudents: {...userContext.myStudents, ...myStudent}};

    projectFirestore.collection('users').doc(userContext.email).update(userRecord)
    .then((doc) => {
      setUserContext(userRecord);
    }).catch((error) => {
      console.log("Error writing userRecord:", error);
    });
  }

  // upsert an activity that's not older than LAST_ACTIVTY_HOURS_AGO
  const logLesson = async (otherParty) => {
    const todaysActivity = await getTodaysActivity(otherParty);

    updateMyStudents(otherParty);

    // we already had an activity with the same participant created LAST_ACTIVTY_HOURS_AGO.
    if (todaysActivity !== null) {
      setActivityLog(todaysActivity);
      return;
    };

    // no activty yet? create a new one
    const participantsMap = {}
    participantsMap[otherParty.email] = otherParty.name;
    participantsMap[userContext.email] = userContext.displayName;
    const pairingId = createPairId(otherParty.email, userContext.email);
    
    projectFirestore.collection(ACTIVITIES_LOCATION)
    .add({
      activityDateTime: utilTimestamp(),
      activityType: ACTIVITY_TYPES.LESSON,
      participantsMap,
      participantsArray: Object.keys(participantsMap),
      pairingId,
      docsUsed: [],
      notesRaw: ''
    })
    .then((docRef) => {
      setActivityLog(docRef);
    })
    .catch((error) => {
      console.error("Error logging activity: ", error);
    });
  }

  // returns an activity with the same participant within the last 24 hours if exists, otherwise `null`
  const getTodaysActivity = async (otherParty) => {
    const xHoursAgo = firebaseTimestamp.now();
    xHoursAgo.seconds = xHoursAgo.seconds - LAST_ACTIVTY_HOURS_AGO * 60 * 60;

    const querySnapshot = await projectFirestore.collection(ACTIVITIES_LOCATION)
    .where("pairingId", "==", createPairId(otherParty.email, userContext.email))
    .where("activityDateTime", ">", xHoursAgo)
    .orderBy("activityDateTime", "desc")
    .limit(1)
    .get();

    if (querySnapshot.empty) {
      return null;
    } else {
      // `querySnapshot.docs[0].ref` is of type DocumentReference
      return querySnapshot.docs[0].ref;
    }
  }

  const openDocument = (doc) => {
    const baseUrl = doc.url.split('?')[0];
    if (doc.type === 'application/pdf' || baseUrl.endsWith('.pdf')) {
      setMusicStand({doc: {...doc}, modifiedBy: userContext.email});
    } else if (doc.type === 'video/webm' || baseUrl.endsWith('.webm')) {
      if (currentBrowser.current.isSafari) {
        alert("Sorry, but recordings doesn't work with Safari.\nUse Chrome, Edge or Forefox instead.");
        return;
      }
      playVideo(doc);
    } else if (doc.type === 'audio/mpeg' || baseUrl.endsWith('.mp3')) {
      playAudio(doc);
    } else {
      alert("Unsupported file format");
    }
  }

  const playAudio = (doc) => {
    if (doc.url === recentAudioUrl.current) {
      setShowAudioPlayerModal(true);
      remoteControlledPlayerRef.current.play();
      return;
    }
    // else: fetch the doc
    initRemoteControlledPlayer();
    setToast("Loading...");
    setFileName("Loading...");
    projectStorage.refFromURL(doc.url).getDownloadURL()
    .then((downloadUrl) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        setFileName(doc.fileName);
        setToast(0);
        setShowAudioPlayerModal(true);
        remoteControlledPlayerRef.current.src = window.URL.createObjectURL(blob);
        recentAudioUrl.current = doc.url;
      };
      xhr.open('GET', downloadUrl);
      xhr.send();
    })
    .catch((e) => {
      console.error(e);
      if (e.serverResponse && JSON.parse(e.serverResponse).error.code === 404) {
        alert("Can't open this document. Looks like the owner deleted it.");
        setShowAudioPlayerModal(false)
        setToast(0);
      }
    });
  }

  const updateCurrentPlayerTime = (newTime) => {
    remoteControlledPlayerRef.current.currentTime = newTime;
  }

  const playVideo = (doc) => {
    setToast("Loading...");
    setFileName("Loading...");
    setShowVideoPlayer(true);
    projectStorage.refFromURL(doc.url).getDownloadURL()
    .then((downloadUrl) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        setFileName(doc.fileName);
        setToast(0);
        replayVideoRef.current.src = window.URL.createObjectURL(blob);
      };
      xhr.open('GET', downloadUrl);
      xhr.send();
    })
    .catch((e) => {
      console.error(e);
      if (e.serverResponse && JSON.parse(e.serverResponse).error.code === 404) {
        alert("Can't open this document. Looks like the owner deleted it.");
        setShowVideoPlayer(false)
        setToast(0);
      }
    });
  }

  const initRemoteControlledPlayer = () => {
    // why we need this see https://developer.chrome.com/blog/autoplay/
    if (audioContext.current?.state === 'suspended') {
      audioContext.current.resume();
    }

    if (audioContext.current) return;

    audioContext.current = new AudioContext();
    const audioSource = audioContext.current.createMediaElementSource(remoteControlledPlayerRef.current);
    
    const gainNode = audioContext.current.createGain();
    gainNode.gain.setValueAtTime(.5, audioContext.current.currentTime);
    audioSource.connect(gainNode);
    gainNode.connect(audioContext.current.destination);

    const pcDestination = audioContext.current.createMediaStreamDestination();
    audioSource.connect(pcDestination);
    mp3AudioTrack.current = pcDestination.stream.getAudioTracks()[0];

    remoteControlledPlayerRef.current.addEventListener('timeupdate', () => {
      setCurrentPlayerTime(remoteControlledPlayerRef.current.currentTime);
    });
    remoteControlledPlayerRef.current.addEventListener('loadedmetadata', () => {
      setPlayerDuration(remoteControlledPlayerRef.current.duration);
    });
    remoteControlledPlayerRef.current.addEventListener('ended', () => {
      setCurrentPlayerTime(-1); // code for "ended"
    });
  }

  const appendDocUsed = async (otherParty, doc, mightNotBeSharedYet) => {

    if (!activityLog || !doc) return;

    const activityLogDoc = await activityLog.get();
    const activityLogData = activityLogDoc.data();

    // check if doc is already logged in the current activity
    if (activityLogData.docsUsed?.hasOwnProperty(doc.url)) return;

    if (!mightNotBeSharedYet) {
    // we don't know if the doc is already shared
      let isDocAlreadyShared = false;
      if (doc.userEmail !== userContext.email) {
        // this is not my doc; test if doc is shared with me
        isDocAlreadyShared = await isDocSharable(userContext.email, doc.url);
      } else {
        // this is my document; test if doc is shared with the other party
        isDocAlreadyShared = await isDocSharable(otherParty.email, doc.url);
      }
      // don't log doc in the activity if doc isn't shared
      if (!isDocAlreadyShared) return;  
    }

    const docToAdd = {};
    docToAdd[doc.url] = doc.fileName;
    activityLog.set({
      docsUsed: {...activityLogData.docsUsed, ...docToAdd}
    }, { merge: true })
  }

  const doShowMediaSettingsModal = () => {
    setShowMediaSettingsModal(true);
  }

  const doHideMediaSettingsModal = () => {
    setShowMediaSettingsModal(false);
  }

  const updateMediaTrackConstraints = (mediaTrackConstraints) => {
    setMediaTrackConstraints(mediaTrackConstraints);
  }

  const doShowAudioOptionsModal = () => {
    setShowAudioOptionsModal(true);
  }

  const doHideAudioOptionsModal = () => {
    setShowAudioOptionsModal(false);
  }

  const doHideAudioPlayerModal = () => {
    setShowAudioPlayerModal(false);
    remoteControlledPlayerRef.current.pause();
  }

  const updateRemoteAudioOptions = (audioOptions) => {
    setRemoteAudioOptions(audioOptions);
  }

  // @sharee is in the format {email: 'displayName'}
  const shareDocument = async (shareeEmail, doc) => {
    if (!doc || !shareeEmail || !userContext.myStudents[shareeEmail]) return;

    const sharee = {[shareeEmail]: userContext.myStudents[shareeEmail]}
    const { url, fileName, bytes, type, userEmail, userName } = doc;

    setToast("Sharing with " + shareeEmail);

    // check if doc is already shared
    const existingShare = await projectFirestore.collection(FILE_STORAGE_LOCATION).doc(shareeEmail).collection(FILE_STORAGE_LOCATION)
    .where("originId", "==", doc.id)
    .get();
    if (!existingShare.empty) {
      alert('This doc is already shared with\n' + shareeEmail);
      return;
    }

    // create a firestore record on the sharee's collection (but don't create a storage record)
    await projectFirestore.collection(FILE_STORAGE_LOCATION).doc(shareeEmail).collection(FILE_STORAGE_LOCATION)
    .add({
      url: url,
      originId: doc.id,
      createdAt: utilTimestamp(),
      updatedAt: utilTimestamp(),
      fileName, bytes, type, userEmail, userName,
    });

    // append a share signal to the original record under `sharees`
    await projectFirestore.collection(FILE_STORAGE_LOCATION).doc(userContext.email).collection(FILE_STORAGE_LOCATION).doc(doc.id)
    .update({
      sharees: {...doc.sharees, ...sharee}
    });

  }

  const toggleRecorder = (timeStamp) => {
    setRecorderTimeStamp(timeStamp);
  }

  return (
    <UserContext.Provider value={[userContext, setUserContext]}>
      <ToastContext.Provider value={toastContext}>
        <MusicStandContext.Provider value={[musicStand, setMusicStand]}>
          <Router>
            <div className="App">

              <div className="controls controlsBackground">
                <Controls
                  isLoggedIn={!!user}
                  connectionStatus={connectionStatus}
                  isLessonScreenMounted={isLessonScreenMounted}
                  doShowAudioOptionsModal={doShowAudioOptionsModal}
                  updateRemoteAudioOptions={updateRemoteAudioOptions}
                  shareDocument={shareDocument}
                  toggleRecorder={toggleRecorder}
                  isRecording={isRecording}
                  setRecordingClicked={setRecordingClicked}
                  setRemoteEndLessonTimeStamp={setRemoteEndLessonTimeStamp}
                  openDocument={openDocument}
                  setPianoData={setPianoData}
                  pianoData={pianoData}
                />
              </div>

              <div className="shaddow-bar">
                <div className="shaddow">
                  <audio ref={remoteControlledPlayerRef} className="audioPlayer" playsInline={true} muted={false} autoPlay={true}/>
                </div>
              </div>

              <div className="content">

                    <Switch>
                      <Route exact path="/signin">
                        <SignInScreen/>
                      </Route>

                      <Route exact path="/">
                        <HomeScreen
                          isLoggedIn={!!user}
                          doShowMediaSettingsModal={doShowMediaSettingsModal}
                          mediaTrackConstraints={mediaTrackConstraints}
                          shareDocument={shareDocument}
                          recorderTimeStamp={recorderTimeStamp}
                          setIsRecording={setIsRecording}
                          openDocument={openDocument}
                        />
                      </Route>

                      <Route path="/:roomId">
                        {userAuthCompleted && <LessonScreen
                          isLoggedIn={!!user}
                          logLesson={logLesson}
                          appendDocUsed={appendDocUsed}
                          activityLog={activityLog}
                          connectionStatus={connectionStatus}
                          updateConnectionStatus={updateConnectionStatus}
                          isLessonScreenMounted={isLessonScreenMounted}
                          setIsLessonScreenMounted={setIsLessonScreenMounted}
                          mediaTrackConstraints={mediaTrackConstraints}
                          updateMediaTrackConstraints={updateMediaTrackConstraints}
                          doShowMediaSettingsModal={doShowMediaSettingsModal}
                          remoteAudioOptions={remoteAudioOptions}
                          shareDocument={shareDocument}
                          recorderTimeStamp={recorderTimeStamp}
                          setIsRecording={setIsRecording}
                          isRecording={isRecording}
                          recordingClicked={recordingClicked}
                          openDocument={openDocument}
                          pianoData={pianoData}
                          setPianoData={setPianoData}
                          mp3AudioTrack={mp3AudioTrack.current}
                          remoteEndLessonTimeStamp={remoteEndLessonTimeStamp}
                        />
                        }
                      </Route>
                    </Switch>
              </div>
              
            </div>
          </Router>
        </MusicStandContext.Provider>

        <UserProfileModal show={!hasUserProfile}/>

        <MediaSettings
          isLoggedIn={!!user}
          showMediaSettingsModal={showMediaSettingsModal}
          doHideMediaSettingsModal={doHideMediaSettingsModal}
          mediaTrackConstraints={mediaTrackConstraints}
          updateMediaTrackConstraints={updateMediaTrackConstraints}
        />

        {showAudioPlayerModal && 
          <AudioPlayer
            showAudioPlayerModal={showAudioPlayerModal}
            doHideAudioPlayerModal={doHideAudioPlayerModal}
            duration={playerDuration}
            currentPlayerTime={currentPlayerTime}
            updateCurrentPlayerTime={updateCurrentPlayerTime}
            setRemoteControlledPlayerState={setRemoteControlledPlayerState}
          />
        }

        <AudioOptions
          doShowAudioOptionsModal={showAudioOptionsModal}
          doHideAudioOptionsModal={doHideAudioOptionsModal}
          mediaTrackConstraints={mediaTrackConstraints}
          updateMediaTrackConstraints={updateMediaTrackConstraints}
          remoteAudioOptions={remoteAudioOptions}
          updateRemoteAudioOptions={updateRemoteAudioOptions}
        />

        {pianoData.show &&
          <RemotePiano
            pianoData={pianoData}
            setPianoData={setPianoData}
          />
        }

        {/* Replay Modal */}
        <VideoPlayer
          showVideoPlayer={showVideoPlayer}
          setShowVideoPlayer={setShowVideoPlayer}
          fileName={fileName}
          replayVideoRef={replayVideoRef}
          videoPlaybackRate={videoPlaybackRate}
          setVideoPlaybackRate={setVideoPlaybackRate}
        />

        {/* Toast Utility */}
        {toastContent && ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: "24px",
              right: "calc(50% - 175px)",
              minWidth: "350px",
              maxWidth: "850px",
              zIndex: "10000000",
              boxShadow: '0px 0px 8px var(--brand100)'
            }}
          >
            <Toast bg="warning">
              <Toast.Body className="toastBody">
                {toastContent}
              </Toast.Body>
            </Toast>
          </div>,
          document.body
        )}

        </ToastContext.Provider>
    </UserContext.Provider>
  );
}

function VideoPlayer(props) {

  const onHideHandler = () => {
    props.setShowVideoPlayer(false);
    props.setVideoPlaybackRate(1);
  }


  return (
      <Modal className="melodistoModal" dialogAs={DraggableModal} centered show={props.showVideoPlayer} onHide={onHideHandler} dialogClassName="largeModal" size="lg" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props.fileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="playBackVideo">
            <video
              ref={props.replayVideoRef}
              className="recorderVideo"
              autoPlay={true}
              playsInline={true}
              muted={false}
              controls={true}
              disablePictureInPicture={false}
            />
          </div>
          <div className="playBackVideo">
            <div className="lever">
              <input className="slider" type="range" min=".5" max="2" step=".1"
                value={props.videoPlaybackRate}
                onChange={(e) => props.setVideoPlaybackRate(parseFloat(e.target.value))}
              />
              <div className="label">Speed: {props.videoPlaybackRate}x</div>
            </div>
          </div>
          
        </Modal.Body>
      </Modal>
  );
}

function UserProfileModal(props) {

  return (
    <Modal className="melodistoModal" centered show={props.show} dialogClassName="largeModal" size="xl" animation={true} backdrop="static">
      <Modal.Header>
        <Modal.Title>Before we start, tell us more about yourself...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserProfile/>
      </Modal.Body>
    </Modal>
  )
}

export default App;
