// react libs
import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from "react-router-dom";

// 3rd party react libs
import GoogleButton from 'react-google-button'
import { useAuthState } from 'react-firebase-hooks/auth';

// 3rd party libs
import firebase from 'firebase/app';

// melodisto components
import { UserContext } from "../App";

// melodisto helper modules
import { DEBUG, projectFirestore, projectAuth, utilTimestamp, FILE_STORAGE_LOCATION } from '../firebase/config';
import facebookLogo from '../res/facebook-logo.svg';

// melodisto styles
import styles from './SignInScreen.module.css';

function SignInScreen(props) {
  DEBUG.COMP_RENDER && console.log('<SignInScreen>');

  const [userContext, setUserContext] = useContext(UserContext);
  const [user] = useAuthState(projectAuth);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // no need to display this screen if they are already logged in
    if (user) {
      props.history.push('/');
    }
  }, [user]);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    projectAuth.signInWithPopup(provider)
    .then( (result) => {
      const userRecord = generateUserRecord(result);

      // create a user record in Firestore
      projectFirestore.collection('users').doc(result.user.email.toLocaleLowerCase()).set(userRecord, {merge: true});

      // create a file record in Firestore
      // TODO: this doesn't seem to work (but that's not causing any issues)
      projectFirestore.collection(FILE_STORAGE_LOCATION).doc(result.user.email.toLocaleLowerCase()).set({
        displayName: result.user?.providerData[0]?.displayName,
      }, {merge: true});

      // set the userContext
      setUserContext(userRecord);
    })
    .catch((error) => {
      console.log(error);
      setErrorMessage(error.message)
    });
  }

  // TODO: follow this recepie: https://firebase.google.com/docs/auth/web/google-signin#popup-mode
  const signInWithFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    projectAuth.signInWithPopup(provider)
    .then( (result) => {
      const userRecord = generateUserRecord(result);

      // create a user record in Firestore
      projectFirestore.collection('users').doc(result.user.email.toLocaleLowerCase()).set(userRecord, {merge: true});

      // create a file record in Firestore
      // TODO: this doesn't seem to work (but that's not causing any issues)
      projectFirestore.collection(FILE_STORAGE_LOCATION).doc(result.user.email.toLocaleLowerCase()).set({
        displayName: result.user?.providerData[0]?.displayName,
      }, {merge: true});

      // set the userContext
      props.setUserContext(userRecord);
    })
    .catch((error) => {
      if (error.code === "auth/account-exists-with-different-credential") {
        setErrorMessage("Looks like you previously signed in via Google using the same email address as your Facebook account. Please use the 'Sign in with Google' button.")
      }
      console.log(error);
    });
  }

  const generateUserRecord = (result) => {
    return {
      lastSignIn: utilTimestamp(),
      email: result.user?.providerData[0]?.email.toLocaleLowerCase(),
      displayName: result.user?.providerData[0]?.displayName,
      uid: result.user?.providerData[0]?.uid,
      photoUrl: result.user?.providerData[0]?.photoURL,
    }
  }

  return (
    <>
      {!user &&
        <div className={styles.loginContainer}>

          <div className={styles.loginButtons}>
            <div className={styles.googleLoginContainer}>
              <GoogleButton onClick={signInWithGoogle } />
            </div>
            
            <div className={styles.signInText}>or</div>
            
            <button className={styles.fbLoginButton} onClick={signInWithFacebook}>
              <img src={facebookLogo} alt="Sign In With Facebook" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sign in with Facebook
            </button>

            <div className={styles.signInText}>
              {errorMessage}
            </div>

          </div>
        </div>
      }
    </>
  )
}

export default withRouter(SignInScreen);