// react libs
import React, { useState, useEffect, useContext } from 'react';

// 3rd party react libs
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// 3rd party libs

// melodisto components
import { UserContext } from "../App";
import { ToastContext } from "../App";

// melodisto helper modules
import { projectFirestore, projectAuth, FILE_STORAGE_LOCATION, ANONYMOUS_EMAIL } from '../firebase/config';

// melodisto styles
import styles from './DocumentSharing.module.css';

// global variables


function DocumentSharing(props) {
  const [userContext] = useContext(UserContext);
  const [sharee, setSharee] = useState({});
  const [shareeEmail, setShareeEmail] = useState('');
  const [currentDoc, setCurrentDoc] = useState();
  const [currentSharees, setCurrentSharees] = useState({});
  const [toast, setToast] = useContext(ToastContext);

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (props.showDocumentSharingModal) {
      fetchCurrentDoc();
    } else {
      setSharee({});
      setShareeEmail('');
      setCurrentDoc();
      setCurrentSharees({});
    }

  }, [props.showDocumentSharingModal]);

  const fetchCurrentDoc = async () => {
    const currentDoc = await projectFirestore.collection(FILE_STORAGE_LOCATION).doc(userContext.email).collection(FILE_STORAGE_LOCATION)
    .doc(props.doc.id)
    .get();
    setCurrentDoc({...currentDoc.data(), id: currentDoc.id});
    setCurrentSharees(currentDoc.data().sharees ? currentDoc.data().sharees : {});
    return currentDoc;
  }

  const onSelectSharee = (email) => {
    setShareeEmail(email);
    setSharee({[email]: userContext.myStudents[email]});
  }
  
  const shareDocument = async (e) => {
    e.preventDefault();

    if (!shareeEmail) return;

    const docToShare = await fetchCurrentDoc();
    props.shareDocument(shareeEmail, {...docToShare.data(), id: docToShare.id});
    setCurrentSharees({...currentSharees, ...sharee});
  }

  const unshareDocument = (shareeEmail) => {

    setToast("Unsharing...");
    // remove the cloned file doc
    projectFirestore.collection(FILE_STORAGE_LOCATION).doc(shareeEmail).collection(FILE_STORAGE_LOCATION)
    .where("originId", "==", currentDoc.id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach(doc => {
        doc.ref.delete();
      });

      // update the list of sharees in the UI
      const updatedSharees = {...currentSharees};
      delete updatedSharees[shareeEmail];
      setCurrentSharees(updatedSharees);

      // remove the sharee entry
      projectFirestore.collection(FILE_STORAGE_LOCATION).doc(userContext.email).collection(FILE_STORAGE_LOCATION)
      .doc(currentDoc.id)
      .update({
        sharees: updatedSharees
      });

    })
    .catch((e) => {
      console.log(e);
    })
  }

  return (
    <>
      <Modal className="melodistoModal" centered show={props.showDocumentSharingModal} onHide={() => props.setShowDocumentSharingModal(false)} dialogClassName="largeModal" size="l" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className="noselect">Sharing Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className={styles.sharingTitle}>{currentDoc?.fileName}</div>

          <CurrentShareesAsNameTags
            currentSharees={currentSharees}
            unshareDocument={unshareDocument}
          />

          <form onSubmit={shareDocument}>
            { userContext.myStudents && 
              <div className={`${styles.selectContainer} mt-5`}>
                <select className="w-100" value={shareeEmail} onChange={(e) => onSelectSharee(e.target.value)}>
                  <option value="">Share this with...</option>
                  { 
                    Object.keys(userContext.myStudents).sort().map((key) => {
                      if (!currentSharees[key] && key !== ANONYMOUS_EMAIL) {
                        return (
                          <option key={key} value={key}>{userContext.myStudents[key] + ' <' + [key] + '>'}</option>
                        )
                      }
                      return null;
                    })
                  }
                </select>
                <button onClick={shareDocument}>Share</button>
              </div>
            }            
            <div className={styles.sharingHint}>Note: You can only share items with people if you were in a call with them at least once.</div>
          </form>

        </Modal.Body>
      </Modal>
    </>
  )
}

function CurrentShareesAsNameTags(props) {

  if (!props.currentSharees) return(<div/>);

  return (
    <div className={styles.shareeList}>
      {Object.keys(props.currentSharees).sort().map((shareeEmail) => {
        return (
          <div className={styles.nameTag} key={shareeEmail}>
            <div className={styles.nameTagAvatar}>{props.currentSharees[shareeEmail]?.charAt(0)}</div>
            <div className={styles.nameTagData}>
              <div className={styles.nameTagName}>{props.currentSharees[shareeEmail]}&nbsp;</div>
              <div className ={styles.nameTagEmail}>{shareeEmail}</div>
            </div>
            <div className={styles.nameTagAction}>
              <FontAwesomeIcon 
                className="fa-button-active fa-lg"
                onClick={() => {props.unshareDocument(shareeEmail)}}
                icon="times-circle"
                role="button"
                title="Unshare"
              />
            </div>
          </div>
        )})
      }
    </div>
  )
}

export default DocumentSharing;