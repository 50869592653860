// react libs
import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';

// 3rd party react libs
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'

// 3rd party libs

// melodisto components
import { UserContext } from "../App";
import Activities from './Activities';

// melodisto helper modules
import { DEBUG, debounce, createPairId } from '../firebase/config';

// melodisto styles
import styles from './NotesRaw.module.css';


function NotesRaw(props) {
  DEBUG.COMP_RENDER && console.log('<NotesRaw>');

  const [userContext] = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('activities'); // 'activities' or 'notes'
  const [notes, setNotes] = useState('');
  const [docsUsed, setDocsUsed] = useState();
  const [dbValue, setDbValue] = useState(null);
  const unsubscribeNotes = useRef(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    return () => {
      unsubscribeNotes?.current?.();
    }
  }, []);

  useEffect(() => {
    if (props.connectionStatus !== 'connected') {
      setActiveTab('activities');
    } else {
      setActiveTab('notes');
    }
  }, [props.connectionStatus]);

  useEffect(() => {
    if (props.activityLog === null) return;

    listenForNotesUpdates(props.activityLog);

    const fetchNotes = async () => {
      const notesDoc = await props.activityLog.get();
      setNotes(notesDoc.data().notesRaw);
    }
    fetchNotes();

  }, [props.activityLog]);

  useEffect(() => {
    if (props.activityLog === null || dbValue === null) return;

    const saveNotes = async () => {
      setIsSaving(true);
      setTimeout(_ => setIsSaving(false), 1000);
      await props.activityLog.set({
        notesRaw: dbValue
      }, { merge: true });
    }
    saveNotes();
  }, [dbValue, props.activityLog]);

  const listenForNotesUpdates = ((activityLog) => {
    let unsubscribeNotes_ = activityLog.onSnapshot(async (snapshot) => {
      const data = snapshot.data();
      if (data) {
        setNotes(data.notesRaw);
        setDocsUsed(data.docsUsed);
      }
    });
    unsubscribeNotes.current = unsubscribeNotes_;
  })

  const debouncedSave = useDebounce((nextValue) => setDbValue(nextValue), 2000);

  const handleNotesUpdate = (event) => {
    const newValue = event.target.value;
    setNotes(newValue);
    debouncedSave(newValue);
  };

  return (
    <div className={`${styles.notesTabContainer} fadein`}>

      {props.isLoggedIn &&
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} defaultActiveKey="activities" id="sharedRecords" className={styles.tabBackground}>

          <Tab eventKey="notes" title="Shared Notes" tabClassName={styles.tabLabel} disabled={props.connectionStatus !== 'connected'}>
            <div className={styles.notesTab + " melodistoCardBodyAndFooter"}>
              <div className="dateSeparator">
                {isSaving && <span>Saving...</span>}
                {!isSaving && <span>Today</span>}
              </div>
              <div className={styles.notesContainer}>
                <div className={styles.notesArea} >
                  <textarea
                    maxLength="1000"
                    value={notes}
                    onChange={handleNotesUpdate} 
                    placeholder="These notes are instantly visible to teacher and student."
                  />
                </div>
              </div>

              <div className={styles.notesDocsUsed}>
                {docsUsed && Object.keys(docsUsed).length > 0 && 
                  <ul className={styles.docsUsedList}>
                    {Object.keys(docsUsed).map((key) => {
                      return (
                        <li key={key}>
                          <div
                            className="pseudoLink"
                            onClick={() => props.openDocument({url: key, fileName: docsUsed[key]})}
                          >
                            {docsUsed[key]}
                          </div>
                        </li>
                      )})
                    }
                  </ul>
                }
              </div>
            </div>
          </Tab>

          <Tab eventKey="activities" title="Shared Activities" tabClassName={styles.tabLabel}>
            <Activities
              pairingId={createPairId(props.roomId, props.guestEmail)}
              openDocument={props.openDocument}
            />
          </Tab>

        </Tabs>
      }
    </div>
  )
}

function useDebounce(callback, delay) {
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay]
  );
  return debouncedFn;
}

export default NotesRaw;