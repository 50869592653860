// react libs
import React, {useEffect, useState, useRef, useContext} from 'react';

// 3rd party react libs
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

// 3rd party libs

// melodisto components
import { MusicStandContext } from "../App";
import DocumentAdd from './DocumentAdd.js';
import AudioMeter from './AudioMeter.js';

// melodisto helper modules
import { DEBUG, MAX_RECORDING_LENGTH, ANONYMOUS_EMAIL } from '../firebase/config';

// melodisto styles
import styles from './VideoRecorder.module.css';

function VideoRecorder(props) {
  DEBUG.COMP_RENDER && console.log('<VideoRecorder>');

  const [musicStand, setMusicStand] = useContext(MusicStandContext);
  const mediaRecorder = useRef();
  const localVideoRef = useRef(null);
  const playBackVideoRef = useRef(null);
  const recordedBlobs = useRef([]);
  const [isRecording, setIsRecording] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [countDown, setCountDown] = useState(3);
  const [fileName, setFileName] = useState();
  const [timedOut, setTimedOut] = useState(false);
  const [share, setShare] = useState(true);
  const timeOut = useRef(null);

  useEffect(() => {
    if (props.recorderTimeStamp === 0) return; // 0 is the initial value
    // else: the user pushed the REC button
    setIsRecording(!isRecording);
    props.setIsRecorderVisible(!isRecording);
  }, [props.recorderTimeStamp]);

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      stopRecording(false);
    }
  }, [isRecording]);

  const startRecording = async () => {
    recordedBlobs.current = [];
    setTimedOut(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia(generateMediaTrackConstraints());
      localVideoRef.current.srcObject = stream;
      await countDownCounter();
      mediaRecorder.current = new MediaRecorder(stream, {mimeType: 'video/webm;codecs=vp8,opus'});
      mediaRecorder.current.onstop = onStopRecordingHandler;
      mediaRecorder.current.ondataavailable = onDataAvailableHandler;
      mediaRecorder.current.start();
      props.setIsRecording(true);
      props.setIsRecorderVisible(true);
      timeOut.current = setTimeout(() => {stopRecording(true); setTimedOut(true) }, MAX_RECORDING_LENGTH);
    } catch (e) {
      console.error(e);
      return;
    }
  }

  const stopRecording = () => {
    clearTimeout(timeOut.current);
    setIsRecording(false)
    props.setIsRecorderVisible(false);
    if (mediaRecorder?.current?.state !== 'inactive') {
      mediaRecorder?.current?.stop();
    }
  }

  const countDownCounter = async () => {
    await new Promise(resolve => setTimeout(resolve, 700));
    setCountDown(2);
    await new Promise(resolve => setTimeout(resolve, 700));
    setCountDown(1);
    await new Promise(resolve => setTimeout(resolve, 700));
    setCountDown(0);
  }

  const onDataAvailableHandler = (event) => {
    if (event.data && event.data.size > 0) {
      recordedBlobs.current.push(event.data);
    }
  }

  const onStopRecordingHandler = () => {
    props.setIsRecording(false);
    props.setIsRecorderVisible(false);
    setFileName(generateFileName());
    setShowModal(true);
    replayVideo();
    
    localVideoRef?.current?.srcObject?.getTracks().forEach((track) => {
      track.stop();
    });

    localVideoRef.current.src = null;
    localVideoRef.current.srcObject = null;
    setCountDown(3);
  }

  const replayVideo = () => {
    if (!recordedBlobs.current.length) return;
    const superBuffer = new Blob(recordedBlobs.current, {type: 'video/webm'});
    playBackVideoRef.current.src = null;
    playBackVideoRef.current.srcObject = null;
    playBackVideoRef.current.src = window.URL.createObjectURL(superBuffer);
    playBackVideoRef.current.controls = true;
    playBackVideoRef.current.play();
  }

  const onFileUploaded = (doc) => {
    setShowModal(false);
    if (props.setBrowserRefreshTimeStamp) {
      props.setBrowserRefreshTimeStamp(Date.now());
    }

    // this is only relevant when recorded during a lesson
    // props.otherParty was passed and user wants to sahre
    if (props.otherParty?.email && share) {
      props.shareDocument(props.otherParty.email, doc);
      props.appendDocUsed(props.otherParty, doc, true);
    }
  }

  const generateFileName = () => {
    const prefix = musicStand?.doc?.fileName ? musicStand.doc.fileName + ' ' : 'REC '
    const now = new Date();
    return prefix + new Date(now).toLocaleString();
  }

  const generateMediaTrackConstraints = () => {
    const mediaTrackConstraints = {
      video: {
        ...props.mediaTrackConstraints.video,
        width: 640,
        height: 480,
      },
      audio: {
        ...props.mediaTrackConstraints.audio,
        autoGainControl: false,
        googExperimentalAutoGainControl: false,
    
        echoCancellation: false,
        googEchoCancellation: false, 
        googExperimentalEchoCancellation: false, 
    
        noiseSuppression: false,
        googExperimentalNoiseSuppression: false,
      },
    }
    return mediaTrackConstraints;
  }

  return (
    <div className={isRecording ? 'mb-2' : 'invisible mb-2'}>
      <div className="melodistoCard">

        <div className="melodistoCardBody">
          <div className={styles.recorder}>
            {(countDown !== 0) && <div className={styles.countDown}>{countDown}</div>}
            {(countDown === 0) && <div className={styles.recordingBlinker}>Recording</div>}
            <video
              ref={localVideoRef}
              className={styles.recorderVideo}
              style={{transform: 'scale(-1, 1)'}}
              autoPlay={true}
              playsInline={true}
              muted={true}
            />
          </div>
          <div className="mt-3">
            {isRecording && <AudioMeter mediaTrackConstraints={props.mediaTrackConstraints}/>}
          </div>
        </div>
      </div>

      <Modal className="melodistoModal" centered show={showModal} onHide={() => setShowModal(false)} backdrop="static" dialogClassName="largeModal" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {timedOut ? "Max. recording time is " + MAX_RECORDING_LENGTH/1000 + " seconds" : "Save your recording"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className={styles.playBackVideo}>
            <video
              ref={playBackVideoRef}
              className={styles.recorderVideo}
              autoPlay={true}
              playsInline={true}
              muted={false}
            />
          </div>

          <div className={styles.fileNameContainer}>
            <div className="mr-2">Save as</div>
            <input
              className={styles.fileName}
              value={fileName}
              onChange={(e) => setFileName(e.target.value.trim())}
              autoFocus 
              onFocus={e => e.currentTarget.select()}
              placeholder="Title of your recording"
            />
          </div>

          {props.otherParty && props.otherParty.email !== ANONYMOUS_EMAIL && 
            <div className={styles.shareOption}>
              <Form.Check
                type="checkbox"
                id="share"
                label={`Share with ${props.otherParty?.name}`}
                onChange={(e) => setShare(e.currentTarget.checked)}
                checked={share}
                custom
              />
            </div>
          }

          <div className={styles.controls}>

            <button className={styles.cancelButton} title="Discard recording" onClick={() => setShowModal(false)}>
              Discard
            </button>

            <DocumentAdd
              file={new File(recordedBlobs.current, fileName, {type: 'video/webm'})}
              onFileUploaded={onFileUploaded}
            />

          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default VideoRecorder;