// react libs
import React, { useState, useContext } from "react"
import ReactDOM from "react-dom"

// 3rd party react libs
import ProgressBar from "react-bootstrap/ProgressBar"
import Toast from "react-bootstrap/Toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// 3rd party libs
import { v4 as uuidV4 } from "uuid"

// melodisto components
import { UserContext } from "../App";

// melodisto helper modules
import { projectFirestore, projectStorage, utilTimestamp, FILE_STORAGE_LOCATION, MAX_FILE_SIZE, MAX_RECORDING_SIZE, MAX_MP3_SIZE } from '../firebase/config';

// melodisto styles


function DocumentAdd(props) {
  const [userContext] = useContext(UserContext);
  const [uploadingFiles, setUploadingFiles] = useState([]);

  function handleUpload(file) {

    let maxFileSize = MAX_FILE_SIZE;
  
    switch (file.type) {
      case 'application/pdf':
        maxFileSize = MAX_FILE_SIZE;
        break;
      case 'video/webm':
        maxFileSize = MAX_RECORDING_SIZE;
        break;
      case 'audio/mpeg':
        maxFileSize = MAX_MP3_SIZE;
        break;
      default:
        // TODO: issue: user could still upload a *.webm file
      	alert("Unsupported file type (only PDF or MP3 allowed).\n");
        return;
    }

    if (file == null || file.size > maxFileSize) {
      alert(`Can't upload this file.\nFile size must not exceed ${maxFileSize / 10e5}MB.`);
      return;
    }

    const id = uuidV4();
    setUploadingFiles((prevUploadingFiles) => [
      ...prevUploadingFiles,
      { id: id, name: file.name, progress: 0, error: false },
    ]);

    const sanitizedFileName = sanitizeFileName(file);

    const uploadTask = projectStorage
    .ref(`/${FILE_STORAGE_LOCATION}/${userContext.email}/${sanitizedFileName}`)
    .put(file);

    uploadTask.on("state_changed", onProgress,

      // on error
      (error) => {
        setUploadingFiles(prevUploadingFiles => {
					console.log("Error uploading ", sanitizedFileName, error.code)
          return prevUploadingFiles.map(uploadFile => {
            if (uploadFile.id === id) {
              return { ...uploadFile, error: true }
            }
            return uploadFile
          })
        })
      },

      // when upload finished
      () => {
        setUploadingFiles(prevUploadingFiles => {
          return prevUploadingFiles.filter(uploadFile => {
            return uploadFile.id !== id;
          })
        })

        uploadTask.snapshot.ref.getDownloadURL().then(url => {

          let doc = {
            url: url.split('?')[0],
            fileName: sanitizedFileName,
            bytes: file.size,
            type: file.type,
            createdAt: utilTimestamp(),
            updatedAt: utilTimestamp(),
            userEmail: userContext.email,
            userName: userContext.displayName,
          };

          // add a timestamp in Firestore
          projectFirestore.collection('files').doc(userContext.email).set({
            lastUpload: utilTimestamp(),
          }, {merge: true});

          // upsert a file record in Firestore
          projectFirestore.collection(FILE_STORAGE_LOCATION).doc(userContext.email).collection(FILE_STORAGE_LOCATION)
          .where("fileName", "==", sanitizedFileName).where("userEmail", "==", userContext.email)
          .get()
          .then(existingFiles => {
            const existingFile = existingFiles.docs[0]
            if (existingFile) {
              doc = {...doc, id: existingFile.id};
              existingFile.ref.update({
                url: doc.url,
                bytes: doc.bytes,
                updatedAt: doc.updatedAt,
              })
              .then(() => {
                props.onFileUploaded(doc);
              })
            } else {
              projectFirestore.collection(FILE_STORAGE_LOCATION).doc(userContext.email).collection(FILE_STORAGE_LOCATION)
              .add(doc)
              .then((docRef) => {
                doc = {...doc, id: docRef.id};
              })
              .then(() => {
                props.onFileUploaded(doc);
              })
            }
          })
          .catch(error => {
            console.log(error);
          })
	      })
      }
    )
  }

  const onProgress = (snapshot) => {
    const progress = snapshot.bytesTransferred / snapshot.totalBytes
    setUploadingFiles(prevUploadingFiles => {
      return prevUploadingFiles.map(uploadFile => {
        return { ...uploadFile, progress: progress }
      })
    })
  }

  const sanitizeFileName = (file) => {
    let sanitizedFileName = file.name.replace(/\//g, '∕').trim(); // replace regular slash with the "division slash - U+2215"
    if (file.type !== 'application/pdf' && sanitizedFileName.endsWith('.pdf')) {
      sanitizedFileName = sanitizedFileName.slice(0, -4);
    }
    if (file.type === 'video/webm' && !sanitizedFileName.endsWith('.webm')) {
      sanitizedFileName += '.webm';
    }
    return sanitizedFileName;
  }

  return (
    <>
      {props.file ? (
        // either pass a file object via a prop which is save if the button is clicked
        <button title="Save recording" onClick={(e) => {handleUpload(props.file); e.target.disabled = true}} style={{width: '130px'}}>
          Save
        </button>
      ) : (
        // or have the component display an "upload file" button
        <label className="btn btn-outline-success btn-sm mb-2 mr-0 ml-0">
          <FontAwesomeIcon icon="file-upload"/>
          <span className="ml-2">Upload PDF / MP3</span>
          <input
            type="file"
            onChange={(e) => {handleUpload(e.target.files[0]); e.target.value = null;}}
            style={{ opacity: 0, position: "absolute", left: "-9999px" }}
          />
        </label>
      )}
      {uploadingFiles.length > 0 &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: "1rem",
              right: "calc(50% - 175px)",
							minWidth: "350px",
              maxWidth: "850px",
              zIndex: "10000000",
            }}
          >
            {uploadingFiles.map(file => (
              <Toast key={file.id}
							  onClose={() => {
                  setUploadingFiles(prevUploadingFiles => {
                    return prevUploadingFiles.filter(uploadFile => {
                      return uploadFile.id !== file.id
                    })
                  })
                }}
              >
                <Toast.Header className="text-truncate w-100 d-block" closeButton={file.error}>
                  Uploading: {file.name}
                </Toast.Header>
                <Toast.Body>
                  <ProgressBar
                    animated={!file.error}
                    variant={file.error ? "danger" : "primary"}
                    now={file.error ? 100 : file.progress * 100}
                    label={file.error ? "Error" : `${Math.round(file.progress * 100)}%`
                    }
                  />
                </Toast.Body>
              </Toast>
            ))}
          </div>,
          document.body
        )
      }
    </>
  )
}

export default DocumentAdd;