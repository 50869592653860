// react libs
import React, { useState, useEffect, useContext } from 'react';

// 3rd party react libs
import { Modal } from 'react-bootstrap';
import 'react-piano/dist/styles.css';

// 3rd party libs

// melodisto components
import { UserContext } from "../App";
import RemotePianoUnit from './RemotePianoUnit';

// melodisto helper modules
import { DEBUG, projectAuth } from '../firebase/config';

// melodisto styles
import styles from './RemotePiano.module.css';

// global variables

function RemotePiano(props) {
  DEBUG.COMP_RENDER && console.log('<RemotePiano>');

  const [userContext] = useContext(UserContext);
  const [activeKeys, setActiveKeys] = useState([]);
  const [displayNotes, setDisplayNotes] = useState([]);
  const [keySignature, setKeySignature] = useState(props.pianoData.keySignature);
  const [mode, setMode] = useState(props.pianoData.mode);
  const [detection, setDetection] = useState("");

  useEffect(() => {
    if (props.pianoData.sender !== userContext.email) {
      setActiveKeys(props.pianoData.activeKeys);
      setDisplayNotes(props.pianoData.displayNotes);
    }
  }, [props.pianoData]);

  useEffect(() => {
    if (props.pianoData.keySignature !== keySignature) {
      props.setPianoData({...props.pianoData, sender: userContext.email, keySignature: keySignature})
    }
  }, [keySignature]);

  useEffect(() => {
    if (props.pianoData.mode !== mode) {
      props.setPianoData({...props.pianoData, sender: userContext.email, mode: mode})
    }
  }, [mode]);

  const hidePiano = () => {
    props.setPianoData({...props.pianoData, sender: userContext.email, show: false})
  }

  return (
    <Modal className={`melodistoModal melodistoModalTopAlign`} centered show={true} onHide={hidePiano} backdrop="static" keyboard={false} dialogClassName={styles.topModal} size="xl" animation={false}>
      <Modal.Header closeButton style={{cursor: 'default'}}>
        <Modal.Title className="noselect">Remote Piano</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.pianoModalBody}>

        <div className={styles.controls}>
          <select className={styles.keySignatureSelector} value={props.pianoData.keySignature} onChange={(e) => setKeySignature(e.target.value)}>
            {KEY_SIGNATURES.map(key => <option key={key.value} value={key.value}>{key.label}</option>)}
          </select>

            <div>{detection}</div>

          <select className={`${styles.keySignatureSelector} ml-4`} value={props.pianoData.mode} onChange={(e) => setMode(e.target.value)}>
            <option value="sequence">• Display as Sequence</option>
            <option value="chords">• Display as Chords</option>
          </select>
        </div>

        <div className={styles.pianoContainer}>

          {/* local piano */}
          <div className={`${styles.piano} ${styles.localPiano}`}>
            <RemotePianoUnit
              keySignature={props.pianoData.keySignature}
              mode={props.pianoData.mode}
              isRemote={false}
              pianoData={props.pianoData}
              setPianoData={props.setPianoData}
              setDetection={setDetection}
            />
          </div>

          {/* remote piano */}
          <div className={`${styles.piano}`}>
            <RemotePianoUnit
              keySignature={props.pianoData.keySignature}
              mode={props.pianoData.mode}
              isRemote={true}
              setPianoData={props.setPianoData}
              activeKeys={activeKeys}
              displayNotes={displayNotes}
            />
          </div>

          {mode === 'chords'   && <div className={styles.hint}>Use [Shift + click] to simulate the piano pedal</div>}
          {mode === 'sequence' && <div className={styles.hint}>Use the [Esc] key to erase your notes from stave</div>}
  
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RemotePiano;

// we need these • bullets in order to prevent keyboard interference
const KEY_SIGNATURES = [
  { value: "Cb", label: '• C♭-Major / A♭-minor'},
  { value: "Gb", label: '• G♭-Major / E♭-minor'},
  { value: "Db", label: '• D♭-Major / B♭-minor'},
  { value: "Ab", label: '• A♭-Major / F-minor'},
  { value: "Eb", label: '• E♭-Major / C-minor'},
  { value: "Bb", label: '• B♭-Major / G-minor'},
  { value: "F",  label: '• F-Major / D-minor'},
  { value: "C",  label: '• C-Major / A-minor'},
  { value: "G",  label: '• G-Major / E-minor'},
  { value: "D",  label: '• D-Major / B-minor'},
  { value: "A",  label: '• A-Major / F♯-minor'},
  { value: "E",  label: '• E-Major / C♯-minor'},
  { value: "B",  label: '• B-Major / G♯-minor'},
  { value: "F#", label: '• F♯-Major / D♯-minor'},
  { value: "C#", label: '• C♯-Major / A♯-minor'},
];
