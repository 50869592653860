// react libs
import React, {useEffect, useState, useMemo, useContext, useRef} from 'react';

// 3rd party react libs
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import BTable from 'react-bootstrap/Table'; // https://react-table.tanstack.com/docs/api/useTable

// 3rd party libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// melodisto components
import { ToastContext } from "../App";
import { UserContext } from "../App";
import DocumentAdd from './DocumentAdd.js'
import DocumentSharing from './DocumentSharing.js'

// melodisto helper modules
import { DEBUG, projectAuth, projectFirestore, projectStorage, FILE_STORAGE_LOCATION, anaylzeBrowser, ANONYMOUS_EMAIL } from '../firebase/config';

// melodisto styles
import styles from './DocumentBrowser.module.css';

function normalizeNFKD(str) {
  if (!str) return '';
  return str.toLowerCase().replaceAll('ß','ss').replaceAll('ø','o').normalize('NFKD').replace(/[^\w]/g, '');
}


function DocumentBrowser(props) {
  DEBUG.COMP_RENDER && console.log('<DocumentBrowser>');

  const [userContext] = useContext(UserContext);
  const [toast, setToast] = useContext(ToastContext);
  const [userFiles, setUserFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [noDocs, setNoDocs] = useState(false);
  const [fileActionUrl, setFileActionUrl] = useState(); // a signal so we don't print the initial blank iFrame onLoad
  const currentBrowser = useRef(anaylzeBrowser());
  const printFrame = useRef();
  const [doc, setDoc] = useState();
  const [showDocumentSharingModal, setShowDocumentSharingModal] = useState(false);

  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "normalizedFileName",
      filter: "text",
      id: "fileName",
      Cell: ({ row }) => 
        <div>
          <div
            // need to add "modifiedBy" in order to detect who opened the doc when we send Room Events
            onClick={() => props.openDocument(row.original)}
            className="pseudoLink truncateContainer">
              <div className="truncatedElement" title={row.original.fileName}>
                {row.original.type === 'application/pdf' && <FontAwesomeIcon className="ml-2 mr-2" icon="file-pdf" title="PDF"/>}
                {row.original.type === 'video/webm' && <FontAwesomeIcon className="ml-2 mr-2 video" icon="video" title="Video"/>}
                {row.original.type === 'audio/mpeg' && <FontAwesomeIcon className="ml-2 mr-2 video" icon="volume-off" title="Audio"/>}
                {row.original.fileName}
              </div>
          </div>
          <div className={styles.originHint}>
            {row.original.userName}
            &nbsp;-&nbsp;
            <span>
              <span>{new Date(1000* row.original.updatedAt.seconds).toLocaleDateString()} </span>
              <span className="text-nowrap">{new Date(1000* row.original.updatedAt.seconds).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>
            </span>
          </div>
        </div>
    },
    {
      Header: "Origin",
      accessor: "normalizedUserName",
      id: "userName",
      filter: "text",
      Cell: ({ row }) => <span className="text-nowrap">{row.original.userName}</span>
    },
    {
      Header: "Size",
      accessor: "bytes",
      sortType: 'basic',
      disableGlobalFilter: true,
      id: "bytes",
      Cell: ({ row }) => <span className="text-nowrap">{row.original.bytes && Math.round(row.original.bytes / 1000)} kB</span>
    },
    {
      Header: "Uploaded",
      accessor: "updatedAt.seconds",
      sortType: 'basic',
      disableGlobalFilter: true,
      id: "updatedAt",
      Cell: ({ row }) => <span>{
        row.original.updatedAt && (
          <span>
            <span>{new Date(1000* row.original.updatedAt.seconds).toLocaleDateString()} </span>
            <span className="text-nowrap">{new Date(1000* row.original.updatedAt.seconds).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>
          </span>
        )        
      }</span>
    },
    {
      Header: "",
      accessor: "actions",
      disableGlobalFilter: true,
      id: "actions",
      Cell: ({ row }) => 
        <div className={styles.actions}>

          {/* print doesn't work in Safari */}
          {(!currentBrowser.current.isSafari && row.original.type === 'application/pdf') ? 
            (
              <div className={styles.buttonFrame} onClick={() => printDocument(row.original)}>
                <FontAwesomeIcon className="fa-button ml-2 mr-2" icon="print" role="button" title="Print"/>
              </div>
            ) : (
              <div className={styles.buttonFrame}/>
            ) 
          }

          <div className={styles.buttonFrame} onClick={() => downloadDocument(row.original)}>
            <FontAwesomeIcon className="fa-button ml-2 mr-2" icon="file-download" role="button" title="Download"/>
          </div>

          {projectAuth.currentUser.email.toLocaleLowerCase() === row.original.userEmail && (
            <div className={styles.buttonFrame} onClick={() => shareDocument(row.original)}>
              <FontAwesomeIcon className="fa-button ml-2 mr-2" icon="share-alt" role="button" title="Share"/>
            </div>
          )}

          {/* you can only delete your own files */}
          {projectAuth.currentUser.email.toLocaleLowerCase() === row.original.userEmail && (
            <div className={styles.buttonFrame} onClick={() => deleteDocument(row.original)}>
              <FontAwesomeIcon className="fa-button ml-2 mr-2" icon="trash-alt" role="button" title="Delete"/>
            </div>
          )}

        </div>
    }
  ], []);

  useEffect(() => {
  }, []);

  useEffect(() => {
    queryDocs();
  }, [props.browerRefreshTimeStamp]);

  const shareDocument = (doc) => {
    setDoc(doc);
    setShowDocumentSharingModal(true);
  }

  const printDocument = (doc) => {
    setToast("Printing...");
    projectStorage.refFromURL(doc.url).getDownloadURL()
    .then((downloadUrl) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        setFileActionUrl(doc.url);
        printFrame.current.src = window.URL.createObjectURL(blob);
        setToast(0);
      };
      xhr.open('GET', downloadUrl);
      xhr.send();
    })
    .catch((e) => {
      console.error(e);
    });
  }

  const downloadDocument = (doc) => {
    setToast("Downloading...");
    projectStorage.refFromURL(doc.url).getDownloadURL()
    .then((downloadUrl) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.setAttribute('download', doc.fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setToast(0);
      };
      xhr.open('GET', downloadUrl);
      xhr.send();
    })
    .catch((e) => {
      console.error(e);
    });
  }

  const queryDocs = async () => {
    if (userContext.email === ANONYMOUS_EMAIL) return;

    const fileTypes = ["application/pdf", "audio/mpeg", "video/webm"];
    const userFilesData = [];
    let mostRecentFile = null; // if a file was just uploaded, `createdAt` is still null; we want to put it aside and add it to the top of the list later
    projectFirestore.collection(FILE_STORAGE_LOCATION).doc(userContext.email).collection(FILE_STORAGE_LOCATION)
    .where("type", "in", fileTypes)
    .limit(500)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((file) => {
        // add normalizedFileName because firestore can't sort case-insensitive
        const fileData = {
          ...file.data(),
          normalizedFileName: normalizeNFKD(file.data().fileName),
          normalizedUserName: normalizeNFKD(file.data().userName),
          id: file.id
        }
        if (file.data().createdAt) {
          userFilesData.push(fileData);
        } else {
          mostRecentFile = fileData;
        }
      });

      setNoDocs(querySnapshot.empty); // a signal to display a blank state message

      // sort the list of files by `normalizedFileName`
      userFilesData.sort((a, b) => (a.normalizedFileName > b.normalizedFileName) ? 1 : ((b.normalizedFileName > a.normalizedFileName) ? -1 : 0));

      // add the file we just uploaded to the top of the list
      if (mostRecentFile) {
        userFilesData.unshift(mostRecentFile);
      }

      setUserFiles(userFilesData);
    })
    .catch((e) => {
      console.error(e);
    });
  }

  const onFileUploaded = (doc) => {
    queryDocs();
  }

  const deleteDocument = async (doc) => {
    setToast("Deleting...");
    const numerOfSharees = doc.sharees ? Object.keys(doc.sharees).length : 0;
    const fileName = doc.fileName;
    
    if (numerOfSharees) {
      if (!window.confirm(`WARNING: this document is shared with ${numerOfSharees} others.\n\nDelete ${fileName}?`)) return;
    } else {
      if (!window.confirm(`Delete ${fileName}?`)) return;
    }

    try {
      const fileRef = projectStorage.ref().child(`/${FILE_STORAGE_LOCATION}/${userContext.email}/${fileName}`);
      await fileRef.delete();      
    } catch(e) {
      console.error(e);
      return;
    }

    // deep-delete all possible "shares" of that file
    projectFirestore.collectionGroup(FILE_STORAGE_LOCATION)
    .where("fileName", "==", fileName).where("userEmail", "==", userContext.email)
    .get()
    .then(existingDocs => {
      existingDocs.forEach((doc) => {
        doc.ref.delete();
      });        
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      // TODO: don't use timeout and rather wait till all delete promises were resolved
      setTimeout(() => queryDocs(), 1000);
      setToast(0);
    })
  }

  return (
    <div className="h-100">

      {!props.asModal && 
        <div className="melodistoCardHeader">
          <FontAwesomeIcon icon="folder-open"/>
          <span className="ml-3">My Music</span>
        </div>
      }

      <div className={`${styles.documentBrowserContainer} melodistoCardBodyAndFooter`}>
        <div className={styles.tableFunctions}>
          <div className={styles.searchBoxContainer}>
            <input className={styles.searchBox} type="search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search" />
          </div>
          <div className="p-0">
              <DocumentAdd onFileUploaded={onFileUploaded}/>
          </div>
        </div>
        <div className={styles.documentList}>
          <Table columns={columns} userFiles={userFiles} searchQuery={searchQuery}/>
          {noDocs && <div>&nbsp;You have no sheetmusic.</div>}
        </div>
      </div>

      {doc && 
        <DocumentSharing
          showDocumentSharingModal={showDocumentSharingModal}
          setShowDocumentSharingModal={setShowDocumentSharingModal}
          shareDocument={props.shareDocument}
          doc={doc}
        />
      }

      <iframe
        ref={printFrame}
        name="printFrame"  id="printFrame" title="printFrame"
        src={null}
        style={{visibility:'hidden'}} width="1" height="1"
        onLoad={() => {if (fileActionUrl) window.printFrame.print()}}
      />

    </div>
  )    
}

function Table(props) {

  const tableInstance = useTable({columns: props.columns, data: props.userFiles }, useGlobalFilter, useSortBy);
  const { getTableProps, headerGroups, rows, prepareRow, setGlobalFilter } = tableInstance;

  useEffect(() => {
    setGlobalFilter(normalizeNFKD(props.searchQuery));
  }, [props.searchQuery]);

  return (
    <>
      <BTable striped bordered hover variant="dark" size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (

             <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.docTableHeader}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </th>

              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className={styles.docTableCell}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
                
              </tr>
            )
          })}
        </tbody>
      </BTable>
    </>    
  )
}

export default DocumentBrowser;