// react libs
import React, {useEffect, useState, useRef, useContext} from 'react';

// 3rd party react libs
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col'
import Select from 'react-select'

// 3rd party libs

// melodisto components
import { UserContext } from "../App";

// melodisto helper modules
import { DEBUG, projectAuth, projectFirestore, utilTimestamp, USER_TYPES } from '../firebase/config';

// melodisto styles
import styles from './UserProfile.module.css';

// global variables
const BUILD_ID = 220602_1;

function UserProfile(props) {
  DEBUG.COMP_RENDER && console.log('<UserProfile>');

  const [userContext, setUserContext] = useContext(UserContext);
  const [userType, setUserType] = useState(null);
  const [instruments, setInstruments] = useState([]);
  const [region, setRegion] = useState();
  const [languages, setLanguages] = useState([]);
  const [url1, setUrl1] = useState('');
  const [url2, setUrl2] = useState('');
  const [agree, setAgree] = useState(false);
  const isSubmittedRef = useRef(false);
  const [isFirstTime, setIsFirstTime] = useState(false)
  const [isEverythingValid, setIsEverythingValid] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState({
    userType: false,
    instruments: false,
    region: false,
    languages: false,
    url1: false,
    url2: false,
    agree: false,
  });

  useEffect(() => {
    setIsError(evaluateErrors());

    if (userContext?.myProfile) {
      setIsFirstTime(false);
      setUserType(userContext.myProfile.userType);
      setInstruments(userContext.myProfile.instruments);
      setRegion(userContext.myProfile.region);
      setLanguages(userContext.myProfile.languages);
      setUrl1(userContext.myProfile.url1);
      setUrl2(userContext.myProfile.url2);
      setAgree(userContext.myProfile.agree);      
    } else {
      setIsFirstTime(true);
    }

  }, [userContext?.email]);

  const userTypeOptions = [
    { value: USER_TYPES.STUDENT, label: 'Student' },
    { value: USER_TYPES.PARENT, label: 'Parent of a Student' },
    { value: USER_TYPES.TEACHER, label: 'Music Teacher' },
    { value: USER_TYPES.THERAPIST, label: 'Speech Therapist' },
  ]

  const regionOptions = [
    { value: 'NA', label: 'North America - West' },
    { value: 'NA', label: 'North America - East' },
    { value: 'LATAM', label: 'Latin America' },
    { value: 'EU', label: 'Europe' },
    { value: 'APAC', label: 'Asia/Pacific' },
  ]

  const languagesOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'zh', label: 'Chinese' },
    { value: 'jp', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
    { value: 'ol', label: 'Other' },
  ]

  const instrumentsOptions = [
    { value: 'Accordion', label: 'Accordion' },
    { value: 'Bagpipes', label: 'Bagpipes' },
    { value: 'Banjo', label: 'Banjo' },
    { value: 'Bass Guitar', label: 'Bass Guitar' },
    { value: 'Beatboxing', label: 'Beatboxing' },
    { value: 'Cajon', label: 'Cajon' },
    { value: 'Cello', label: 'Cello' },
    { value: 'Drums', label: 'Drums' },
    { value: 'Euphonium', label: 'Euphonium' },
    { value: 'Flute', label: 'Flute' },
    { value: 'French Horn', label: 'French Horn' },
    { value: 'Glockenspiel', label: 'Glockenspiel' },
    { value: 'Guitar (Classic)', label: 'Guitar (Classic)' },
    { value: 'Guitar (Electric)', label: 'Guitar (Electric)' },
    { value: 'Guitar (Western)', label: 'Guitar (Western)' },
    { value: 'Harmonica', label: 'Harmonica' },
    { value: 'Harp', label: 'Harp' },
    { value: 'Keyboard', label: 'Keyboard' },
    { value: 'Lute', label: 'Lute' },
    { value: 'Mandolin', label: 'Mandolin' },
    { value: 'Marimba', label: 'Marimba' },
    { value: 'Organ', label: 'Organ' },
    { value: 'Piano', label: 'Piano' },
    { value: 'Recorder', label: 'Recorder' },
    { value: 'Singing (Vocal)', label: 'Singing (Vocal)' },
    { value: 'Sitar', label: 'Sitar' },
    { value: 'Steel Drums', label: 'Steel Drums' },
    { value: 'Trombone', label: 'Trombone' },
    { value: 'Trumpet', label: 'Trumpet' },
    { value: 'Tuba', label: 'Tuba' },
    { value: 'Ukulele', label: 'Ukulele' },
    { value: 'Upright Bass', label: 'Upright Bass' },
    { value: 'Vibraphone', label: 'Vibraphone' },
    { value: 'Viola', label: 'Viola' },
    { value: 'Violin', label: 'Violin' },
    { value: 'Voice', label: 'Voice' },
    { value: 'Xylophone', label: 'Xylophone' },
    { value: 'N/A', label: 'N/A' },
  ]

  const isInputValid = () => {
    const hasErrors = evaluateErrors();
    setIsError(hasErrors);
    return !hasErrors.userType 
      && !hasErrors.instruments
      && !hasErrors.region
      && !hasErrors.languages
      && !hasErrors.url1
      && !hasErrors.url2
      && !hasErrors.agree;
  }

  const handleSave = () => {
    isSubmittedRef.current = true;
    setIsEverythingValid(isInputValid());

    if (!isInputValid()) return;

    setIsSaving(true);
    const myProfile = {
      userType: userType,
      instruments: instruments,
      region: region,
      languages: languages,
      url1: url1,
      url2: url2,
      agree: agree,
    };

    setUserContext({...userContext, type: userType.value, myProfile: myProfile, email: projectAuth.currentUser.email.toLocaleLowerCase()});

    const userRecord = (isFirstTime) ?
      {
        type: userType.value,
        myProfile: myProfile,
        agreed: utilTimestamp(),
      } : {
        type: userType.value,
        myProfile: myProfile,
      };

    projectFirestore.collection('users').doc(projectAuth.currentUser.email.toLocaleLowerCase()).update(userRecord)
    .then((doc) => {
      setIsFirstTime(false);
      setTimeout(() => setIsSaving(false), 1500);
    }).catch((error) => {
      console.log("Error writing userRecord:", error);
    });
  }

  const evaluateErrors = () => {
    return {
      userType: !userType,
      instruments: instruments.length === 0 && userType?.value !== USER_TYPES.THERAPIST,
      region: !region,
      languages: languages.length === 0,
      url1: url1.trim() !== '' && !validateUrl(url1),
      url2: url2.trim() !== '' && !validateUrl(url2),
      agree: !agree,
    }
  }

  const validateUrl = (value) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  return (
    <div className="melodistoCard">

      <div className="melodistoCardBody">

        <Form>

          <Form.Row>
            <Col className={`${styles.formColumn} p-2`}>
              <div className="mb-4">
                <Form.Label className="my-1 mr-2" htmlFor="userType">
                  I am a...
                </Form.Label>
                <Select
                  options={userTypeOptions}
                  onChange={(data) => setUserType(data)}
                  className={isSubmittedRef.current && isError.userType ? styles.error : styles.validHigher}
                  value={userType}
                  isDisabled={!isFirstTime}
                />
                <Form.Text id="userTypeHelp" muted>
                  &nbsp;
                </Form.Text>
              </div>

              {(userType?.value !== USER_TYPES.THERAPIST) && (
                <div className="mb-4">
                  <Form.Label className="my-1 mr-2" htmlFor="instruments">
                    {userType?.value === USER_TYPES.STUDENT ? "I use these instruments:" : ""}
                    {userType?.value === USER_TYPES.PARENT ? "My child uses these instruments:" : ""}
                    {userType?.value === USER_TYPES.TEACHER ? "I teach these instruments:" : ""}
                    {!userType ? "My instruments:" : ""}
                  </Form.Label>
                  <Select
                    isMulti
                    options={instrumentsOptions}
                    onChange={(data) => setInstruments(data)}
                    className={isSubmittedRef.current && isError.instruments ? styles.error : styles.valid}
                    value={instruments}
                  />
                  <Form.Text id="instrumentsHelp" muted>
                    This will help us to optimize the audio experience accordingly.
                  </Form.Text>
                </div>
              )}
            </Col>
            <Col className={`${styles.formColumn} p-2`}>
              <div className="mb-4">
                <Form.Label className="my-1 mr-2" htmlFor="region">
                  I live in this region:
                </Form.Label>
                <Select
                  options={regionOptions}
                  onChange={(data) => setRegion(data)}
                  className={isSubmittedRef.current && isError.region ? styles.error : styles.validHigher}
                  value={region}
                />
                <Form.Text id="regionHelp" muted>
                  This will help us to optimize the network latency accordingly.
                </Form.Text>
              </div>

              <div className="mb-4">
                <Form.Label className="my-1 mr-2" htmlFor="languages">
                  {userType?.value === USER_TYPES.TEACHER ? "I teach in these languages:" : ""}
                  {userType?.value === USER_TYPES.PARENT ? "My child speaks these langauges:" : ""}
                  {userType?.value !== USER_TYPES.TEACHER && userType?.value !== USER_TYPES.PARENT? "I speak these langauges:" : ""}
                </Form.Label>
                <Select
                  options={languagesOptions}
                  isMulti
                  onChange={(data) => setLanguages(data)}
                  className={isSubmittedRef.current && isError.languages ? styles.error : styles.valid}
                  value={languages}
                  />
                <Form.Text id="languagesHelp" muted>
                  This will help us to prioritize the tanslations of the app accordingly.
                </Form.Text>
              </div>

            </Col>
          </Form.Row>

          <Form.Row>
            <Col className={styles.formColumn}>
              {userType?.value === USER_TYPES.TEACHER && (
                <div className="mt-2">
                  <Form.Control
                    type="url"
                    placeholder="Main link to your online profile"
                    onChange={(e) => setUrl1(e.target.value)}
                    value={url1}
                    className={isSubmittedRef.current && isError.url1 ? styles.error : styles.valid}
                  />
                  <Form.Control
                    type="text"
                    onChange={(e) => setUrl2(e.target.value)}
                    value={url2}
                    placeholder="Other link to your online profile"
                    className={isSubmittedRef.current && isError.url2 ? styles.error : styles.valid}
                  />
                  <Form.Text muted>Point us to your online profile (e.g. your web site, lessonface.com, TakeLessons.com, thumbtack.com, etc.)</Form.Text>
                </div>
              )}
            </Col>
            <Col className={styles.formColumn}>
              <div className="mb-2 mt-2 pl-3">
                <Form.Check
                  type="checkbox"
                  className={isSubmittedRef.current && isError.agree ? styles.error : '' + " my-1 mr-sm-2"}
                  id="agree"
                  label="I agree to the Terms of Service"
                  onChange={(e) => setAgree(e.currentTarget.checked)}
                  checked={agree}
                  disabled={!isFirstTime}
                  custom
                />
                <Form.Text id="languagesHelp">
                  Find the <a href="https://www.melodisto.com/tos" target="tos">Terms of Service</a> on our web page.
                </Form.Text>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </div>

      <div className="melodistoCardFooter">
        <div className={styles.buttonBar}>
          {!isEverythingValid && <div style={{color:"#d51010"}}>Please enter complete and valid information.</div>}
          <button onClick={handleSave} disabled={isSaving}>{isSaving ? "Saving..." : "Save"}</button>
        </div>
      </div>

      <Form.Text muted>Build {BUILD_ID}</Form.Text>
    </div>
  )    
}

export default UserProfile;