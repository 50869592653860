// react libs
import React, { useState, useRef, useEffect, useContext } from 'react';

// 3rd party react libs
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// 3rd party libs

// melodisto components
import { UserContext } from "../App";
import { MusicStandContext } from "../App";
import DocumentHighlighter from './DocumentHighlighter';

// melodisto helper modules
import { DEBUG, projectAuth, projectStorage, debounce, isDocSharable } from '../firebase/config';


// melodisto styles
import styles from './DocumentViewer.module.css';

const SCROLL_DEBOUNCE_INTERVAL = 500; // miliseconds
const PDF_BASE_WIDTH = 600;

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

function DocumentViewer(props) {
  DEBUG.COMP_RENDER && console.log('<DocumentViewer>');

  const [userContext] = useContext(UserContext);
  const [musicStand, setMusicStand] = useContext(MusicStandContext);
  const [numPages, setNumPages] = useState(null);
  const [zoomFactor, setZoomFactor] = useState(1);
  const docContainerRef = useRef();
  const musicStandRef = useRef();
  const pdfDocContainerRef = useRef();
  const [pdfDocContainerDimensions, setPdfDocContainerDimensions] = useState({width: PDF_BASE_WIDTH, height: 200});
  const isUserHostRef = useRef(false);
  const [remoteTopOffset, setRemoteTopOffset] = useState(0);
  const [isDocAlreadyShared, setIsDocAlreadyShared] = useState(true);
  const musicStandUrl = useRef(null);
  const [downloadUrl, setDownloadUrl] = useState();
  const [scrollListenerEnabled, setScrollListenerEnabled] = useState(true);
  const isMounted = useRef(false);

// TODO this appears to be dated
  // user is host => props.guestEmail is the other party
  // user is guest => props.roomId is the other party
  const otherParty = {email: props.roomId === userContext.email ? props.guestEmail : props.roomId};

  useEffect(() => {
    isMounted.current = true;
    isUserHostRef.current = props.roomId === userContext.email;

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      isMounted.current = false;
      window.removeEventListener('resize', debouncedHandleResize);
    }

  }, []);

  useEffect(() => {
    if (musicStand.doc.url) {
      // get a the doc URL without a possibly expired access token
      projectStorage.refFromURL(musicStand.doc.url).getDownloadURL()
      .then((downloadUrl) => {
        setDownloadUrl(downloadUrl);
      })
      .catch((e) => {
        console.error(e);
        if (e.serverResponse && JSON.parse(e.serverResponse).error.code === 404) {
          alert("Can't open this document. Looks like the owner deleted it.");
          clearCurrentDocument();
        }
      })
    }
  }, [musicStand.doc.url]);


  const debouncedHandleResize = debounce(() => {
    handleResize();
  }, 2000);

  const handleResize = () => {
    const pdfDocContainerDimensions = {
      width: docContainerRef.current.clientWidth,
      height: docContainerRef.current.clientHeight,
    }

    if (isUserHostRef.current) {
      setMusicStand({
        ...musicStand,
        hostDimension : pdfDocContainerDimensions,
        modifiedBy: userContext.email
      });
    } else {
      setMusicStand({
        ...musicStand,
        guestDimension : pdfDocContainerDimensions,
        modifiedBy: userContext.email
      });
    }
  }

  useEffect(() => {
    if (!musicStand) return;

    if (musicStand.modifiedBy === userContext.email) return;

    if (props.connectionStatus === 'connected'
        && musicStandUrl.current !== musicStand.doc.url
        && userContext.email === musicStand.doc.userEmail) {
      // with a short delay, check wether we should display the <SharePrompt>
      const querySharing = async () => {
        setIsDocAlreadyShared(await isDocSharable(otherParty.email, musicStand.doc.url));
      }
      setTimeout(_ => querySharing(), 1000);
    }
    musicStandUrl.current = musicStand.doc.url;

    // handle a remote scroll
    if (musicStand.scrollPosition && musicStand.scrollPosition.top !== musicStandRef.current.scrollTop) {
      setScrollListenerEnabled(false);
      musicStandRef.current.scrollTo({
        left: 0,
        top: musicStand.scrollPosition.top * zoomFactor,
        behavior: 'smooth',
      });
    }

    // handle updated screen dimensions
    if (musicStand.hostDimension) {
      setRemoteTopOffset(docContainerRef.current.clientHeight - musicStand.hostDimension.height)
    }    
    if (musicStand.guestDimension) {
      setRemoteTopOffset(docContainerRef.current.clientHeight - musicStand.guestDimension.height)
    }

  }, [musicStand, otherParty.email, props.connectionStatus]);

  const debouncedOnScroll = debounce(() => {
    // don't update the music stand in case this was a remote scroll
    if (!scrollListenerEnabled) {
      setScrollListenerEnabled(true);
      return;
    }
    // else update the music stand so the remote party will be notified
    setMusicStand({
      ...musicStand,
      scrollPosition : {
        top: musicStandRef.current.scrollTop / zoomFactor,
      },
      modifiedBy: userContext.email
    });
  }, SCROLL_DEBOUNCE_INTERVAL);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    if (!isMounted.current) return;
    setNumPages(nextNumPages);
    handleResize();
  }

  const onLastPageRendered = () => {
    if (!pdfDocContainerRef.current) return;
    const pdfDocContainerDimensions = {
      width: pdfDocContainerRef.current.clientWidth,
      height: pdfDocContainerRef.current.clientHeight,
    }
    setTimeout(() => {
      setPdfDocContainerDimensions(pdfDocContainerDimensions);
    }, 500);
  }

  const clearCurrentDocument = () => {
    setMusicStand(null);
  }

  const shareMusic = async () => {
    if (!musicStand || !props.roomId) return;

    if (isDocAlreadyShared) return;
  
    const shareeEmail = (props.roomId === userContext.email) ? props.guestEmail : props.roomId;
    props.shareDocument(shareeEmail, musicStand.doc);

    setIsDocAlreadyShared(true);
    
    // append the doc to the activity log
    setTimeout(_ => props.appendDocUsed(otherParty, musicStand.doc), 2000);
  }

  const dontShareMusic = () => {
    setIsDocAlreadyShared(true);
  }

  const zoomIn = () => {
    setZoomFactor(zoomFactor +  0.2);
  }
  const zoomOut = () => {
    setZoomFactor(zoomFactor - 0.2);
  }

  return (
    <div className="melodistoCard fullUnit fadein">
      <div className={styles.musicStandContainer}>
        <div className="melodistoCardHeader">
          <div className={styles.headerBar}>
            <div className={styles.titleBar}>
              <FontAwesomeIcon 
                className="fa-button-active fa-lg"
                onClick={() => clearCurrentDocument()}
                icon="arrow-left"
                role="button"
              />
              <div className={styles.docNameContainer}>
                <div className={styles.docName}>
                  {musicStand.doc.fileName}
                </div>
              </div>
            </div>
            <div className={styles.buttonBar}>
              <FontAwesomeIcon 
                className="fa-button-active fa-lg mr-3"
                onClick={() => zoomOut()}
                icon="search-minus"
                role="button"
              />
              <span style={{fontWeight: 'normal'}}>{Math.round(zoomFactor * 100)}%</span>
              <FontAwesomeIcon 
                className="fa-button-active fa-lg ml-3"
                onClick={() => zoomIn()}
                icon="search-plus"
                role="button"
              />
              <span className={styles.separator}>&#65372;</span>
              <FontAwesomeIcon 
                className="fa-button-active fa-lg"
                onClick={() => clearCurrentDocument()}
                icon="times-circle"
                role="button"
              />
            </div>
          </div>
        </div>
        <div>
          {!isDocAlreadyShared &&
            <div className="melodistoCardBody">
              <SharePrompt doc={musicStand?.doc} shareMusic={shareMusic} dontShareMusic={dontShareMusic}/>
            </div>
          }
        </div>
        <div className={styles.musicStandContent} ref={docContainerRef}>
          <div className={styles.musicStand} ref={musicStandRef} onScroll={debouncedOnScroll}>
          <div
            className={styles.scrollCover}
            style={{
              display: scrollListenerEnabled ? 'none' : 'inline',
              width: `calc(${docContainerRef?.current?.clientWidth - 16}px)`,
              height: `calc(${docContainerRef?.current?.clientHeight - 16}px)`,
            }}
          />
         {remoteTopOffset > 0 &&
          <div
            className={styles.remoteCover + ' ' + styles.remoteCoverDimensions}
            title="The other party can't see this area because their screen is smaller than yours."
            style={{
              height: `calc(${remoteTopOffset}px)`,
            }}>
               ↓  ↓  ↓ The other party can't see this area because their screen is smaller than yours.  ↓  ↓  ↓
          </div>}

            <Document
              file={downloadUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              className={styles.pdfDocContainer}
              inputRef={pdfDocContainerRef}
              >
              {
                Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={PDF_BASE_WIDTH * zoomFactor}
                      className={styles.pdfPageContainer}
                      onRenderSuccess={numPages-1 === index ? onLastPageRendered : null}
                    />
                  ),
                )
              }
              <Page
                key={'blank1'}
                width={PDF_BASE_WIDTH * zoomFactor}
                className={styles.pdfPageContainerBlank}
                height="A4"
                pageNumber={1}
              />
              <Page
                key={'blank2'}
                width={PDF_BASE_WIDTH * zoomFactor}
                className={styles.pdfPageContainerBlank}
                height="A4"
                pageNumber={1}
              />
            </Document>
            <DocumentHighlighter
              pdfDocContainerDimensions={pdfDocContainerDimensions}
              roomId={props.roomId}
              zoomFactor={zoomFactor}
            />

          </div>
        </div>
      </div>
    </div>
  ) 
}

function SharePrompt(props) {
  return (
    <div className={`${styles.shareMessage} fadein`}>
      <div>
        Share this document?
      </div>
      <div >
        <button onClick={props.shareMusic}>Yes</button>
      </div>
      <div >
        <button onClick={props.dontShareMusic}>No</button>        
      </div>
      <div className={styles.shareInfo}>
        Note: After this session is over, the other party won't have access to this document, unless you share it.
      </div>
    </div>
  )
}

export default DocumentViewer;