// react libs
import React, { useState, useEffect, useRef, useContext } from 'react';

// 3rd party react libs
import { Modal, Form } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'; // https://gitbrent.github.io/bootstrap-switch-button-react/

// 3rd party libs

// melodisto components
import { UserContext } from "../App";
import AudioMeter from './AudioMeter.js';

// melodisto helper modules
import { DEBUG, DEFAULT_MEDIA_TRACK_CONSTRAINTS, anaylzeBrowser, projectFirestore } from '../firebase/config';

// melodisto styles

// global variables

function MediaSettings(props) {
  DEBUG.COMP_RENDER && console.log('<MediaSettings>');

  const [userContext] = useContext(UserContext);
  const [videoOptions, setVideoOptions] = useState([]);
  const [audioInputOptions, setAudioInputOptions] = useState([]);
  const [hideAdvanced, setHideAdvanced] = useState(true);
  const tempStream = useRef(null); // need this to run enumerateDevices() on Safari AND to run findCurrentDevices()
  const currentBrowser = useRef(anaylzeBrowser());

  useEffect(() => {

    // onHide of the modal, disable camera and mic
    if (!props.showMediaSettingsModal) {
      tempStream.current?.getTracks().forEach(track => {
        track.stop();
      });
      tempStream.current = null;
      return;
    };

    // fetch data and generate options for the <select> element
    async function detectUserMedia() {
      // Firefox returns an error if getUserMedia() is called twice
      if (!currentBrowser.current.isFirefox) {
        // call getUserMedia, so enumerateDevices() also works in Safari
        tempStream.current = await navigator.mediaDevices.getUserMedia(DEFAULT_MEDIA_TRACK_CONSTRAINTS);
      }

      findCurrentDevices();

      const deviceInfos = await navigator.mediaDevices.enumerateDevices();

      const audioInputOptions_ = [];
      const videoOptions_ = [];
      const regex = RegExp(/^[A-Za-z]/); // remove IDs and such from the device label
  
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        const option = {};
        option.id = deviceInfo.deviceId;
        option.value = deviceInfo.deviceId;
        option.deviceId = deviceInfo.deviceId;
        if (deviceInfo.kind === 'videoinput') {
          const label = deviceInfo.label || `camera ${videoOptions_.length + 1}`;
          option.label = label.split(' ').filter((word) => regex.test(word)).join(' ');
          videoOptions_.push(option);
        } else if (deviceInfo.kind === 'audioinput') {
          const label = deviceInfo.label || `microphone ${audioInputOptions_.length + 1}`;
          option.label = label.split(' ').filter((word) => regex.test(word)).join(' ');
          audioInputOptions_.push(option);
        }
      }
      setVideoOptions(videoOptions_);
      setAudioInputOptions(audioInputOptions_);
    }
    detectUserMedia();

  }, [props.showMediaSettingsModal]);


  // identify the device IDs of the currently active MediaStream (tempStream)
  const findCurrentDevices = () => {
    // we already have defaults; no action needed
    if (props.mediaTrackConstraints.videoDeviceId && props.mediaTrackConstraints.audioDeviceId) return;

    let videoDeviceId = null;
    if (!props.mediaTrackConstraints.videoDeviceId) {
      tempStream.current?.getTracks().forEach(track => {
        if (track.kind === 'video') {
          videoDeviceId = track.getSettings().deviceId;
        }
      })
    }

    let audioDeviceId = null;
    if (!props.mediaTrackConstraints.audioDeviceId) {
      tempStream.current?.getTracks().forEach(track => {
        if (track.kind === 'audio') {
          audioDeviceId = track.getSettings().deviceId;
        }
      })
    }

    props.updateMediaTrackConstraints({...props.mediaTrackConstraints, audioDeviceId : audioDeviceId, videoDeviceId : videoDeviceId});
  }

  const updateAutoGainControl = (isChecked) => {
    props.updateMediaTrackConstraints({
      ...props.mediaTrackConstraints,
      audio: {
        ...props.mediaTrackConstraints.audio,
        autoGainControl: isChecked,
        googExperimentalAutoGainControl: isChecked,
      }
    })
  }

  const updateNoiseSuppression = (isChecked) => {
    props.updateMediaTrackConstraints({
      ...props.mediaTrackConstraints,
      audio: {
        ...props.mediaTrackConstraints.audio,
        noiseSuppression: isChecked,
        googExperimentalNoiseSuppression: isChecked,
      }
    })
  }

  const updateEchoCancellation = (isChecked) => {
    props.updateMediaTrackConstraints({
      ...props.mediaTrackConstraints,
      audio: {
        ...props.mediaTrackConstraints.audio,
        echoCancellation: isChecked,
        googEchoCancellation: isChecked, 
        googExperimentalEchoCancellation: isChecked,
      }
    })
  }

  const updateAndStoreVideoTrackConstraints = (e) => {
    const deviceLabel = e.target[e.target.selectedIndex].text
    props.updateMediaTrackConstraints({
      ...props.mediaTrackConstraints,
      video: {
        deviceId: {exact: [e.target.value]},
        deviceLabel: deviceLabel
      },
      videoDeviceId: e.target[e.target.selectedIndex].getAttribute('data-deviceid')
    });
    if (props.isLoggedIn) {
      projectFirestore.collection('users').doc(userContext.email).set({preferredVideoInput : deviceLabel}, {merge: true});
    }
  }

  const updateAndStoreAudioTrackConstraints = (e) => {
    const deviceLabel = e.target[e.target.selectedIndex].text
    props.updateMediaTrackConstraints({
      ...props.mediaTrackConstraints,
      audio: {
        ...props.mediaTrackConstraints.audio,
        deviceId: {exact: [e.target.value]}, 
        deviceLabel: deviceLabel
      },
      audioDeviceId : e.target[e.target.selectedIndex].getAttribute('data-deviceid')
    });
    if (props.isLoggedIn) {
      projectFirestore.collection('users').doc(userContext.email).set({preferredAudioInput : deviceLabel}, {merge: true});
    }
  }

  return (
    <>
      <Modal className="melodistoModal" centered show={props.showMediaSettingsModal} onHide={props.doHideMediaSettingsModal} dialogClassName="largeModal" size="l" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className="noselect" onDoubleClick={() => setHideAdvanced(!hideAdvanced)}>Audio/Video Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <Form.Label className="ml-1 mr-2 switchLabel">
              Select your video source
            </Form.Label>
            <select
              className="w-100"
              value={props.mediaTrackConstraints.videoDeviceId || ''}
              onChange={(e) => updateAndStoreVideoTrackConstraints(e)}
            >
              {
                videoOptions.map((item) => {
                  return (
                    <option key={item.id} value={item.id} data-deviceid={item.deviceId}>{item.label}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="mb-4">
            <Form.Label className="ml-1 mr-2 switchLabel">
              Select your audio source
            </Form.Label>
            <select
              className="w-100"
              value={props.mediaTrackConstraints.audioDeviceId || ''}
              onChange={(e) => updateAndStoreAudioTrackConstraints(e)}
            >
              {
                audioInputOptions.map((item) => {
                  return (
                    <option key={item.id} value={item.id} data-deviceid={item.deviceId}>{item.label}</option>
                  )
                })
              }
            </select>
          </div>
          {props.showMediaSettingsModal && <AudioMeter mediaTrackConstraints={props.mediaTrackConstraints}/>}

          <div className={hideAdvanced ? 'invisible' : ''}>
            <div className="mb-2 mt-5">
              <BootstrapSwitchButton
                checked={props.mediaTrackConstraints.audio.autoGainControl}
                onlabel='On'
                offlabel='Off'
                onChange={(checked) => updateAutoGainControl(checked)}
                size="s" 
              />
              <span className="switchLabel">Auto-Adjust Microphone Volume</span>
            </div>

            <div className="mb-2">
              <BootstrapSwitchButton
                checked={props.mediaTrackConstraints.audio.noiseSuppression}
                onlabel='On'
                offlabel='Off'
                onChange={(checked) => updateNoiseSuppression(checked)}
                size="s" 
              />
              <span className="switchLabel">Noise Suppression</span>
            </div>

            <div className="mb-2">
              <BootstrapSwitchButton
                checked={props.mediaTrackConstraints.audio.echoCancellation}
                onlabel='On'
                offlabel='Off'
                onChange={(checked) => updateEchoCancellation(checked)}
                size="s" 
              />
              <span className="switchLabel">Echo Cancellation</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default MediaSettings;