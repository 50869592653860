// react libs
import React, { useState, useEffect, useRef } from 'react';

// 3rd party react libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// 3rd party libs

// melodisto components

// melodisto helper modules

// melodisto styles
import styles from './AudioMeter.module.css';

// global variables
const CLIP_LEVEL = 60;

function AudioMeter(props) {
  const isMounted = useRef(false)
  const volumeInterval = useRef();
  const volumeBarRef = useRef();
  const [volumeLevel, setVolumeLevel] = useState(0);
  const tempStream = useRef(null); // need this to run enumerateDevices() on Safari AND to run findCurrentDevices()

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      const volumeIntervalCurrent = volumeInterval.current;
      clearTimeout(volumeIntervalCurrent);
      stopTracks();
      tempStream.current = null;
    }
  }, []);

  
  useEffect(() => {
    stopTracks();

    async function detectUserMedia() {
      tempStream.current = await navigator.mediaDevices.getUserMedia(props.mediaTrackConstraints);
      const audioContext = new AudioContext();
      const audioSource = audioContext.createMediaStreamSource(tempStream.current);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 512;
      analyser.minDecibels = -127;
      analyser.maxDecibels = 0;
      analyser.smoothingTimeConstant = 0.4;
      audioSource.connect(analyser);
      const volumes = new Uint8Array(analyser.frequencyBinCount);
      const volumeCallback = () => {
        analyser.getByteFrequencyData(volumes);
        let volumeSum = 0;
        for(const volume of volumes) {
          volumeSum += volume;
        }
        const averageVolume = volumeSum / volumes.length;
        if (isMounted.current) {
          // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
          setVolumeLevel(Math.round(averageVolume * 100 / 127));
        }
      };
      clearTimeout(volumeInterval.current);
      volumeInterval.current = setInterval(volumeCallback, 50);
    }
    detectUserMedia();

  }, [props.mediaTrackConstraints]);


  const stopTracks = (tracks) => {
    tempStream.current?.getTracks().forEach(track => {
      track.stop();
    });
  }

  return (
    <div className={styles.audioMeter}>
      <FontAwesomeIcon className="mr-2" icon="volume-up"/>
      <div className={styles.volumeIndicator}>
      {volumeLevel < CLIP_LEVEL && <div className={styles.volumeBar} ref={volumeBarRef} style={{width: volumeLevel}}></div>}
      {volumeLevel >= CLIP_LEVEL && <div className={styles.volumeBarClipped} ref={volumeBarRef} style={{width: volumeLevel}}></div>}
      </div>
    </div>
  )
}

export default AudioMeter;