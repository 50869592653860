import ModalDialog from 'react-bootstrap/ModalDialog'
import Draggable from "react-draggable";

function DraggableModal(props) {
  return (
    <Draggable handle=".modal-header">
      <ModalDialog {...props}/>
    </Draggable>
  );
}
  
export default DraggableModal;