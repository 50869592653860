// react libs
import React from 'react';

// 3rd party react libs
import { Modal } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'; //https://gitbrent.github.io/bootstrap-switch-button-react/

// 3rd party libs

// melodisto components
import DraggableModal from './DraggableModal.js';

// melodisto helper modules
import { DEBUG } from '../firebase/config';

// melodisto styles

// global variables

function AudioOptions(props) {
  DEBUG.COMP_RENDER && console.log('<AudioOptions>');

  const updateAutoGainControl = (isUnChecked) => {
    console.log('updateAutoGainControl', isUnChecked)
    props.updateRemoteAudioOptions({
      ...props.remoteAudioOptions,
      autoGainControl: isUnChecked,
      googExperimentalAutoGainControl: isUnChecked,
    })
  }

  const updateNoiseSuppression = (isUnChecked) => {
    console.log('updateNoiseSuppression', isUnChecked)
    props.updateRemoteAudioOptions({
      ...props.remoteAudioOptions,
      noiseSuppression: isUnChecked,
      googExperimentalNoiseSuppression: isUnChecked,
    })
  }

  const updateEchoCancellation = (isUnChecked) => {
    props.updateRemoteAudioOptions({
      ...props.remoteAudioOptions,
      echoCancellation: isUnChecked,
      googEchoCancellation: isUnChecked, 
      googExperimentalEchoCancellation: isUnChecked,
    })
    console.log('echoCancellation: ', !isUnChecked)
    props.updateMediaTrackConstraints({
      ...props.mediaTrackConstraints,
      audio: {
        ...props.mediaTrackConstraints.audio,
        echoCancellation: isUnChecked,
        googEchoCancellation: isUnChecked, 
        googExperimentalEchoCancellation: isUnChecked,
      }
    })
  }

  return (
    <>
      <Modal className="melodistoModal" dialogAs={DraggableModal} centered show={props.doShowAudioOptionsModal} onHide={props.doHideAudioOptionsModal} dialogClassName="largeModal" size="xl" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className="noselect">Remote Audio Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="mb-4 mt-2">
            This allows you to adjust the student's audio settings in real time.
          </div>
          <div className=''>

            <div className="mb-2">
              <BootstrapSwitchButton
                checked={props.remoteAudioOptions.autoGainControl}
                onlabel='On'
                offlabel='Off'
                onChange={(checked) => updateAutoGainControl(checked)}
                size="s" 
              />
              <span className="switchLabel">Auto-Adjust Microphone Volume</span>
            </div>
            <div className="muted">
              Disable this for instruments or sound sources that have high attack. Examples:
              <ul>
                <li>the student is playing drums, piano, bass guitar, etc.</li>
                <li>the student's metronome is causing a tremolo effect (a.k.a. ducking)</li>
              </ul>              
            </div>
            <br/>

            <div className="mb-2">
              <BootstrapSwitchButton
                checked={props.remoteAudioOptions.noiseSuppression}
                onlabel='On'
                offlabel='Off'
                onChange={(checked) => updateNoiseSuppression(checked)}
                size="s" 
              />
              <span className="switchLabel">Noise Suppression</span>
            </div>
            <div className="muted">
              Disable noise suppression in situations where you need to pay attention to very quiet or very high pitch parts. Examples:
              <ul>
                <li>during piano lessons you can't really discern the student's proper use of the pedal</li>
                <li>during flute lessons the student plays very quiet but high pitched notes</li>
              </ul>
            </div>
            <br/>

            <div className="mb-2">
              <BootstrapSwitchButton
                checked={props.remoteAudioOptions.echoCancellation}
                onlabel='On'
                offlabel='Off'
                onChange={(checked) => updateEchoCancellation(checked)}
                size="s" 
              />
              <span className="switchLabel">Echo Cancellation (Student and Teacher)</span>
            </div>
            <div className="muted">
              <div className="mb-2">
                <b>Note:</b> You and the student must <b>use headphones</b> in order to prevent feedback loops.
              </div>
              Disable echo cancellation if:
              <ul>
                <li>if you want to talk to the student while they are playing (and vice versa)</li>
                <li>you want to minimize the time lag</li>
              </ul>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default AudioOptions;