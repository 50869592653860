// react libs
import React, { useState, useEffect, useContext } from 'react';

// 3rd party react libs
import { DateTime } from "luxon";

// 3rd party libs

// melodisto components
import { UserContext } from "../App";

// melodisto helper modules
import { DEBUG, projectFirestore, ACTIVITIES_LOCATION, ACTIVITY_TYPES, ANONYMOUS_EMAIL } from '../firebase/config';

// melodisto styles
import styles from './Activities.module.css';


function Activities(props) {
  DEBUG.COMP_RENDER && console.log('<Activities>');

  const [userContext] = useContext(UserContext);
  const [activities, setActiivities] = useState([]);

  useEffect(() => {
    if (props.pairingId) {
      querySharedActivities(props.pairingId);
    } else {
      queryMyActivities();
    }
  }, [props.browerRefreshTimeStamp]);

  const queryMyActivities = async () => {
    if (userContext.email === ANONYMOUS_EMAIL) return;

    let activitiesData = [];
    projectFirestore.collection(ACTIVITIES_LOCATION)
    .where("participantsArray", "array-contains", userContext.email)
    .orderBy("activityDateTime", "desc")
    .limit(30)
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        activitiesData.push({...doc.data(), id: doc.id});
      });
      setActiivities(activitiesData);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const querySharedActivities = async (pairingId) => {
    if (userContext.email === ANONYMOUS_EMAIL) return;

    let activitiesData = [];
    projectFirestore.collection(ACTIVITIES_LOCATION)
    .where("pairingId", "==", pairingId)
    .orderBy("activityDateTime", "desc")
    .limit(30)
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        activitiesData.push({...doc.data(), id: doc.id});
      });
      setActiivities(activitiesData);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (  
    <div className={styles.activities + " melodistoCardBodyAndFooter"}>

      {activities && activities.map((act, index, array) => 
        <Activity key={act.id} activity={act} openDocument={props.openDocument} />
      )}

      {!activities.length && <NoActivity/>}

    </div>
  )
}

function NoActivity(props) {
  return (
    <div className={styles.activity}>
      <div className={styles.activityText}>
        <div className="fadein">
          <div><br/>No past activities<br/><br/></div>
        </div>
      </div>
    </div>
  )
}

function Activity(props) {
  const [userContext] = useContext(UserContext);
  const { activityDateTime, activityType, participantsMap, docsUsed, notesRaw } = props.activity;
  const currentDate = activityDateTime?.seconds ? DateTime.fromSeconds(activityDateTime.seconds) : null;
  const [participantName, setParticipantName] = useState();
  
  useEffect(() => {
    // evaluate the other party's display name
    if (participantsMap) {
      const participantKeySet = new Set(Object.keys(participantsMap))
      participantKeySet.delete(userContext.email);
      const participantKeyArray = Array.from(participantKeySet);
      if (!!participantKeyArray[0]) {
        setParticipantName(participantsMap[participantKeyArray[0]]);
      }
    }
  }, [participantsMap]);


  return (
    <>
      {currentDate && <div className="dateSeparator">{currentDate.toLocaleString({ month: 'long', day: 'numeric' })}</div>}
      <div className={styles.activity}>
        <div className={styles.activityText}>

          {activityType === ACTIVITY_TYPES.LESSON && (
            <>
              {participantName && <div>Session with {participantName}</div>}

              {notesRaw && <div className={styles.docsUsed}>Notes:</div>}
              {notesRaw && 
                <div className={styles.activityNotesRaw}>
                  {notesRaw}
                </div>
              }

              {docsUsed && Object.keys(docsUsed).length > 0 && <div className={styles.docsUsed}>Going over:</div>}
              {docsUsed && Object.keys(docsUsed).length > 0 && 
                <ul className={styles.docsUsedList}>
                  {Object.keys(docsUsed).map((key, i) => {
                    return (
                      <li key={key}>
                        <div
                          className="pseudoLink"
                          onClick={() => props.openDocument({url: key, fileName: docsUsed[key]})}
                        >
                          {docsUsed[key]}
                        </div>
                      </li>
                    )})
                  }
                </ul>
              }
            </>      
          )}
        </div>
      </div>
    </>
  )
}


export default Activities;