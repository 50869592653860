// react libs
import React, { useState, useEffect, useRef, useContext } from 'react';
import Vex from "vexflow";

// 3rd party react libs
import { Piano } from "react-piano";
import 'react-piano/dist/styles.css';

// 3rd party libs
import { Chord } from "@tonaljs/tonal";

// melodisto components
import { UserContext } from "../App";

// melodisto helper modules
import { DEBUG, projectAuth } from '../firebase/config';

// melodisto styles
import styles from './RemotePiano.module.css';

// global variables

function RemotePianoUnit(props) {
  DEBUG.COMP_RENDER && console.log('<RemotePianoUnit>');

  const [userContext] = useContext(UserContext);
  const OSCILATORS = useRef([]);
  const trebbleStave = useRef();
  const bassStave = useRef();
  const audioContext = useRef();
  const [shiftHeld, setShiftHeld] = useState(false);
  const [displayNotes, setDisplayNotes] = useState([]);
  const [outboundData, setOutboundData] = useState({activeKeys: [], displayNotes : []});
  const [detection, setDetection] = useState("");

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    audioContext.current = new AudioContext();

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
      stopAll();
    };
  }, []);

  useEffect(() => {
    drawMusic(trebbleStave.current, 'treble', []);
    drawMusic(bassStave.current, 'bass', []);
    stopAll();
  }, [props.keySignature]);

  useEffect(() => {
    drawMusic(trebbleStave.current, 'treble', []);
    drawMusic(bassStave.current, 'bass', []);
    stopAll();
  }, [props.mode]);

  useEffect(() => {
    if (props.isRemote) {
      drawMusic(trebbleStave.current, 'treble', props.displayNotes);
      drawMusic(bassStave.current, 'bass', props.displayNotes);
      if (!props.displayNotes?.length) {
        setDisplayNotes([]);
      }
    }
  }, [props.displayNotes]);

  useEffect(() => {
    if (displayNotes.length >= 3 && displayNotes.length <= 5) {
      detectChord(displayNotes);
    } else if (displayNotes.length === 8) {
      detectMode(displayNotes);
    } else {
      setDetection("");
    }
  }, [displayNotes]);

  useEffect(() => {
    if (props.setDetection) {
      props.setDetection(detection);
    }
  }, [detection]);

  useEffect(() => {
    drawMusic(trebbleStave.current, 'treble', outboundData.displayNotes);
    drawMusic(bassStave.current, 'bass', outboundData.displayNotes);
    if (!props.isRemote) {
      props.setPianoData({...props.pianoData, sender: userContext.email, activeKeys: outboundData.activeKeys, displayNotes: outboundData.displayNotes});
    }
  }, [outboundData]);

  useEffect(() => {
    if (!shiftHeld) {
      stopAll();
    }
  }, [shiftHeld]);

  const drawMusic = (container, clef, displayNotes) => {
    if (props.mode === 'chords') {
      drawMusicAsChords(container, clef, displayNotes);
    } else {
      drawMusicAsSequence(container, clef, displayNotes);
    }
  }

  const drawMusicAsSequence = (container, clef, displayNotes) => {
    if (container === null) return;
    container.innerHTML = "";
    const staveWidth = 400;

    const vf = Vex.Flow;
    const renderer = new vf.Renderer(container, vf.Renderer.Backends.SVG);
    renderer.resize(staveWidth, 140);
    const context = renderer.getContext();
    
    const stave = new vf.Stave(0, 0, staveWidth);
    stave.addClef(clef).addKeySignature(props.keySignature);
    stave.setContext(context).draw();

    const displayNotes_ = (clef === 'treble') ? displayNotes.filter(key => key >= 57) : displayNotes.filter(key => key <= 64);

    if (!displayNotes_.length) return;

    const staveNotes = [];
    displayNotes_.forEach(key => {
      const activeKey = getNoteFromMidiNumber(key, props.keySignature);
      const staveNote = new vf.StaveNote({clef: clef, keys: [activeKey.key + '/' + activeKey.octave], duration: "q" });
      if (!props.isRemote) {
        staveNote.setStyle({ strokeStyle: "black", fillStyle: "black" });
      } else {
        staveNote.setStyle({ strokeStyle: "blue", fillStyle: "blue" });
      }
      if (activeKey.accidental) {
        staveNote.addAccidental(0, new vf.Accidental(activeKey.accidental));
      } 
      staveNotes.push(staveNote)
    });

    if (!staveNotes.length) return;

    var voice = new vf.Voice()
    voice.setStrict(false);
    voice.addTickables(staveNotes);
    var formatter = new vf.Formatter().joinVoices([voice]).format([voice], staveNotes.length * 25);
    voice.draw(context, stave);
  }

  const drawMusicAsChords = (container, clef, displayNotes) => {
    if (container === null) return;
    container.innerHTML = "";

    const vf = Vex.Flow;
    const renderer = new vf.Renderer(container, vf.Renderer.Backends.SVG);
    renderer.resize(200, 140)
    const context = renderer.getContext();
    const stave = new vf.Stave(0, 0, 200);
    stave.addClef(clef).addKeySignature(props.keySignature)
    stave.setContext(context).draw();

    const displayNotes_ = (clef === 'treble') ? displayNotes.filter(key => key >= 57) : displayNotes.filter(key => key <= 64);

    if (!displayNotes_.length) return;

    const activeNotes = [];
    displayNotes_.forEach(key => {
      const activeKey = getNoteFromMidiNumber(key, props.keySignature);
      activeNotes.push(activeKey.key + '/' + activeKey.octave)
    });

    if (!activeNotes.length) return;
    
    const staveNotes = new vf.StaveNote({clef: clef, keys: activeNotes, duration: "w" });
    if (!props.isRemote) {
      staveNotes.setStyle({ strokeStyle: "black", fillStyle: "black" });
    } else {
      staveNotes.setStyle({ strokeStyle: "blue", fillStyle: "blue" });
    }

    displayNotes_.forEach((key, idx) => {
      const activeKey = getNoteFromMidiNumber(key, props.keySignature);
      if (activeKey.accidental) {
        staveNotes.addAccidental(idx, new vf.Accidental(activeKey.accidental));
      } 
    });

    var voice = new vf.Voice()
    voice.addTickables([staveNotes]);
    var formatter = new vf.Formatter().joinVoices([voice]).format([voice], 150);
    voice.draw(context, stave);
  }

  const downHandler = (evt) => {
    if (evt.key === 'Shift') {
      setShiftHeld(true);
    }
    if (evt.key === 'Escape') {
      setDisplayNotes([]);
      setOutboundData({activeKeys: [], displayNotes : []})
    }
  }

  const upHandler = ({key}) => {
    if (key === 'Shift') {
      setShiftHeld(false);
      stopAll();
    }
  }

  const playNote = (midiNumber) => {
    if (OSCILATORS.current[midiNumber]) {
      return
    };

    // can't display more than 14 notes at once
    if (props.mode !== 'chords' && outboundData.displayNotes.length > 14) {
      return
    };

    const activeKeys_ = [...outboundData.activeKeys, midiNumber];
    const displayNotes_ = [...displayNotes, midiNumber];
    if (props.mode === 'chords') {
      // notes must be sorted on chord mode
      setDisplayNotes(displayNotes_.sort());
      setOutboundData({activeKeys: activeKeys_.sort(), displayNotes : displayNotes_.sort()})
    } else {
      // order matters in sequence mode
      setDisplayNotes(displayNotes_);
      setOutboundData({activeKeys: activeKeys_, displayNotes : displayNotes_})
    }

    const instrument = 2;
    OSCILATORS.current[midiNumber] = [
        [...`1248`], // 🎹 organ
        [...`3579`], // 🎷 brass
        [...`123`],  // 🎻 strings
        [...`1`],    // ∿ sine
    ][ instrument ].map((j) => {
      const osc = audioContext.current.createOscillator();
      osc.connect(
        osc.g = audioContext.current.createGain(
          osc.frequency.value = j * 110 * 1.05946 ** (midiNumber - 45)
        )
      )
      .connect(audioContext.current.destination);
      osc.g.gain.value = .2 / (1 + Math.log2(j));
      osc.type = 'sine';
      osc.start();
      return osc;
    })
  }

  const stopNote = (midiNumber) => {
    if (shiftHeld) return;
    if (!OSCILATORS.current[midiNumber]) return;

    OSCILATORS.current[midiNumber].map(osc => {
      osc.g.gain.linearRampToValueAtTime(osc.g.gain.value, audioContext.current.currentTime);
      osc.g.gain.linearRampToValueAtTime(0, audioContext.current.currentTime + .3);
      OSCILATORS.current[midiNumber] = 0;
      return setTimeout(e => osc.stop(), 300);
    });
    OSCILATORS.current[midiNumber] = null;

    if (props.mode === 'chords') {
      setDisplayNotes([]);
    }
    setOutboundData({...outboundData, activeKeys: []})
  }

  const stopAll = () => {
    OSCILATORS.current.forEach((oscNodes) => {
      if (!oscNodes) return;
      oscNodes.forEach((osc) => {
        if (!osc) return;
        osc.g.gain.linearRampToValueAtTime(osc.g.gain.value, audioContext.current.currentTime);
        osc.g.gain.linearRampToValueAtTime(0, audioContext.current.currentTime + .3);
        osc = 0;
        return setTimeout(e => {if (osc) osc.stop()}, 300);
      })
    })
    OSCILATORS.current = [];
    setDisplayNotes([]);
    setOutboundData({activeKeys: [], displayNotes : []})
  }

  const detectChord = (displayNotes) => {
    const noteNames = [];
    displayNotes.forEach((midiNote) => {
      noteNames.push(getNoteNameFromMidiNumber(midiNote, props.keySignature));
    });
    const detected = Chord.detect(noteNames);
    if (detected.length) {
      setDetection(((`${detected[0]}`) +  (detected[1] ? ` (or ${detected[1]})` : '')).replace(/b/g, '♭').replace(/#/g, '♯'));
    }
  }

  const detectMode = (displayNotes) => {
    const pattern = displayNotes.sort();
    let template = "";
    for (let i = 0; i<7; i++) {
      template += (pattern[i+1] - pattern[i]).toString();
    }
    setDetection([MODES[template]]);
  }

  return (
    <div className={styles.pianoUnit}>
      <div className={styles.musicCanvas}>
        <div ref={bassStave} className={styles.bassStaveContainer}/>
        <div ref={trebbleStave} className={styles.trebleStaveContainer}/>
      </div>

        <Piano
          keyWidthToHeight={30}
          noteRange={{ first: 36, last: 84 }}
          keyboardShortcuts={props.isRemote ? [] : KEYBOARD_SHORTCUTS}
          playNote={playNote}
          stopNote={stopNote}
          activeNotes={props.activeKeys}
          className={props.isRemote ? "" : styles.localKeyboard}
        />

    </div>
  )
}

export default RemotePianoUnit;

function getNoteFromMidiNumber(midiNote, keySignature) {
  const sharpAccidentals = ["F#","C#","G#","D#","A#","E#","B#"];
  const flatAccidentals = ["Bb","Eb","Ab","Db","Gb","Cb","Fb"];
  const keySignatures = {
    'Cb': new Set(flatAccidentals.slice(0,7)),
    'Gb': new Set(flatAccidentals.slice(0,6)),
    'Db': new Set(flatAccidentals.slice(0,5)),
    'Ab': new Set(flatAccidentals.slice(0,4)),
    'Eb': new Set(flatAccidentals.slice(0,3)),
    'Bb': new Set(flatAccidentals.slice(0,2)),
    'F':  new Set(flatAccidentals.slice(0,1)),
    'C':  new Set([]),
    'G':  new Set(sharpAccidentals.slice(0,1)),
    'D':  new Set(sharpAccidentals.slice(0,2)),
    'A':  new Set(sharpAccidentals.slice(0,3)),
    'E':  new Set(sharpAccidentals.slice(0,4)),
    'B':  new Set(sharpAccidentals.slice(0,5)),
    'F#': new Set(sharpAccidentals.slice(0,6)),
    'C#': new Set(sharpAccidentals.slice(0,7)),
  }

  const isFlatKeySignature = keySignature === 'F' || keySignature[1] === 'b';
  const keySignatureAccidentals = isFlatKeySignature ? 'b' : '#';
  const noteNames = isFlatKeySignature ? ["C","Db","D","Eb","E","F","Gb","G","Ab","A","Bb","B"] : ["C","C#","D","D#","E","F","F#","G","G#","A","A#","B"];
  const keyName = noteNames[midiNote % 12];

  return {
    key: keyName,
    octave: Math.floor((midiNote / 12) - 1),
    line: ["C","D","E","F","G","A","B"].indexOf(keyName[0]),
    accidental: 
      keySignatures[keySignature].has(keyName) ? null : (
        keySignatures[keySignature].has(keyName + keySignatureAccidentals) ? 'n' : (
          keyName.endsWith(keySignatureAccidentals) ? keySignatureAccidentals : null
        )
      )
  }
}

function getNoteNameFromMidiNumber(midiNote, keySignature) {
  const isFlatKeySignature = keySignature === 'F' || keySignature[1] === 'b';
  const noteNames = isFlatKeySignature ? ["C","Db","D","Eb","E","F","Gb","G","Ab","A","Bb","B"] : ["C","C#","D","D#","E","F","F#","G","G#","A","A#","B"];
  return noteNames[midiNote % 12];
}

const MODES = {
  '2212221' : 'Major (Ionian) Scale',
  '2122212' : 'Dorian Scale',
  '1222122' : 'Phrygian Scale',
  '2221221' : 'Lydian Scale',
  '2212212' : 'Mixolydian Scale',
  '2122122' : 'Natural Minor (Aeolian) Scale',
  '1221222' : 'Locrian Scale'
}


const KEYBOARD_SHORTCUTS = [
  { key: 'q', midiNumber: 48},
  { key: '2', midiNumber: 49},
  { key: 'w', midiNumber: 50},
  { key: '3', midiNumber: 51},
  { key: 'e', midiNumber: 52},
  { key: 'r', midiNumber: 53},
  { key: '5', midiNumber: 54},
  { key: 't', midiNumber: 55},
  { key: '6', midiNumber: 56},
  { key: 'y', midiNumber: 57},
  { key: '7', midiNumber: 58},
  { key: 'u', midiNumber: 59},

  { key: 'c', midiNumber: 60},
  { key: 'f', midiNumber: 61},
  { key: 'v', midiNumber: 62},
  { key: 'g', midiNumber: 63},
  { key: 'b', midiNumber: 64},
  { key: 'n', midiNumber: 65},
  { key: 'j', midiNumber: 66},
  { key: 'm', midiNumber: 67},
  { key: 'k', midiNumber: 68},
  { key: ',', midiNumber: 69},
  { key: 'l', midiNumber: 70},
  { key: '.', midiNumber: 71},
  { key: '/', midiNumber: 72},

  { key: 'z', midiNumber: 57},
  { key: 's', midiNumber: 58},
  { key: 'x', midiNumber: 59},

  { key: 'i', midiNumber: 60},
  { key: '9', midiNumber: 61},
  { key: 'o', midiNumber: 62},
  { key: '0', midiNumber: 63},
  { key: 'p', midiNumber: 64},
  { key: '[', midiNumber: 65},
  { key: '=', midiNumber: 66},
  { key: ']', midiNumber: 67},
];
