import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/performance";
import "firebase/analytics";

const FILE_STORAGE_LOCATION = 'files';
const MAX_FILE_SIZE = 4 * 1000 * 1000;  // don't forget to update "storage.rules" if you update this value
const MAX_RECORDING_SIZE = 30 * 1000 * 1000; // don't forget to update "storage.rules" if you update this value
const MAX_MP3_SIZE = 10 * 1000 * 1000; // don't forget to update "storage.rules" if you update this value
const MAX_RECORDING_LENGTH = 180 * 1000;

const ANONYMOUS_EMAIL = 'unknown@melodisto.com';

// [COMP_RENDER, SIGNALING, ...]
const DEBUG = {
  COMP_RENDER: false,
  SIGNALING: false,
};

const ACTIVITIES_LOCATION = 'activities';
const ACTIVITY_TYPES = {
  LESSON: 'lesson',
}

const USER_TYPES = {
  STUDENT: 'student',
  PARENT: 'parent',
  TEACHER: 'teacher',
  THERAPIST: 'therapist'
}

const ANALYTICS_EVENTS = {
  STARTED: 'started',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  ENDED: 'ended',
  TIMED_OUT: 'timedOut',
  RETRIED: 'retried',
}

const DEFAULT_MEDIA_TRACK_CONSTRAINTS = {
  video: {deviceId: 'default'},
  audio: {
    deviceId: 'default',
    latency: {ideal: 0},
    sampleRate: {ideal: 48000},
    sampleSize: {ideal: 16},
    volume: 1.0,

    autoGainControl: false,
    googExperimentalAutoGainControl: false,

    echoCancellation: true,
    googEchoCancellation: true, 
    googExperimentalEchoCancellation: true, 

    noiseSuppression: true,
    googExperimentalNoiseSuppression: true,
  },
  // not part of MediaTrackConstraints; stores the groupId of the currently selected device so the MediaSettings modal "remembers" what's currently selected
  videoDeviceId : null,
  audioDeviceId : null,
}

const DEFAULT_REMOTE_AUDIO_OPTIONS = {
  autoGainControl: false,
  googExperimentalAutoGainControl: false,
  echoCancellation: true,
  googEchoCancellation: true, 
  googExperimentalEchoCancellation: true,
  noiseSuppression: true,
  googExperimentalNoiseSuppression: true,
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY, 
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID 
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const perf = firebase.performance();
const analytics = firebase.analytics();
const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const rtcPeerConfiguration = {
  iceServers: [
    {
      urls: [
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302',
      ],
    },
  ],
  iceCandidatePoolSize: 10,
}; 

// generate a firebase server time stamp
// never use the plain JS method for this purpose!
const utilTimestamp = firebase.firestore.FieldValue.serverTimestamp;

const firebaseTimestamp = firebase.firestore.Timestamp;

// returns true if a doc with 'docUrl' is shared with a user with 'shareeEmail'
const isDocSharable = async (shareeEmail, docUrl) => {
  // never share a doc with an anonymous user.
  if (shareeEmail === ANONYMOUS_EMAIL) return true;

  const filesRef = projectFirestore.collection(FILE_STORAGE_LOCATION).doc(shareeEmail).collection(FILE_STORAGE_LOCATION);
  const querySnapshot = await filesRef.where("url", "==", docUrl).get();
  return !querySnapshot.empty;
}

// generate deterministic unique ID out of two IDs, regardless of order
const createPairId = (id1, id2) => {
  let idArray = [];
  idArray.push(id1);
  idArray.push(id2);
  return idArray.sort().join("&");
}

const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

const anaylzeBrowser = () => {
  const { detect } = require('detect-browser');
  const browser = detect();

  const currentBrowser = {};
  currentBrowser['isOpera'] = browser.name === 'opera';
  currentBrowser['isFirefox'] = browser.name === 'firefox';
  currentBrowser['isSafari'] = browser.name === 'safari';
  currentBrowser['isIE'] =  browser.name === 'ie';
  currentBrowser['isEdge'] = browser.name === 'edge';
  currentBrowser['isChrome'] = browser.name === 'chrome';
  return currentBrowser;
}

const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export {
  perf,
  analytics,
  projectStorage,
  projectFirestore,
  projectAuth,
  rtcPeerConfiguration,
  utilTimestamp,
  createPairId,
  isDocSharable,
  FILE_STORAGE_LOCATION,
  MAX_RECORDING_SIZE,
  MAX_RECORDING_LENGTH,
  MAX_FILE_SIZE,
  MAX_MP3_SIZE,
  ACTIVITIES_LOCATION,
  ACTIVITY_TYPES,
  USER_TYPES,
  debounce,
  firebaseTimestamp,
  DEFAULT_MEDIA_TRACK_CONSTRAINTS,
  anaylzeBrowser,
  isValidEmail,
  DEFAULT_REMOTE_AUDIO_OPTIONS,
  ANALYTICS_EVENTS,
  DEBUG,
  ANONYMOUS_EMAIL,
};